
import React from 'react';
import Header from '../Comp/Header';
import Category from '../Comp/Category';
import Footer from '../Comp/Footer';
import Video from '../assets/video/furnishing.mp4';
import ReactPlayer from "react-player/lazy";
import { Helmet } from 'react-helmet';

const FurnishingZone = () => {
    return (
        <>
            <Helmet>
                <title>Furnishings Exhibition Zone | Hospitality | Hide India Expo 2024 </title>
                <meta name="description"
                    content="Choosing the right style for your furnishings is absolutely crucial when you come to kit out or replace the fittings in your establishment." />
                <meta name="keywords"
                    content="Hospitality Interior & Design Expo 2024,Book Your Exhibition Stall, Exhibition Stall  Creative Directors , Business Development Directors, Architects, Heads of Marketing , Graphic Designers , Brand Manager , India's leading event, Interior & Design Exhibition, Trade Fair on Furniture, Furniture Trade Fair, Trade Fair on Furnishings, Trade Fair on Interiors Design, Exhibition of Lighting Meterials, Lighting Trade Fair, Surface designing exhibitions" />

                {/* Og Tags */}
                <meta property="og:url" content="https://hideindia.com/furnishing-zone" />
                <meta property="og:title" content="Furnishings Exhibition Zone | Hospitality | Hide India Expo 2024 " />
                <meta property="og:description" content="Choosing the right style for your furnishings is absolutely crucial when you come to kit out or replace the fittings in your establishment." />
                <meta name="og:site_name" content="Hospitality Interior & Design Expo 2024" />
                <meta property="og:image" content="https://hideindia.com/assets/images/inner-banner/Furnishing_zone.webp" />

                {/* Twiter Tags */}
                <meta property="twitter:url" content="https://hideindia.com/furnishing-zone" />
                <meta property="twitter:title" content="Furnishings Exhibition Zone | Hospitality | Hide India Expo 2024 " />
                <meta property="twitter:description" content="Choosing the right style for your furnishings is absolutely crucial when you come to kit out or replace the fittings in your establishment." />
                <meta name="twitter:keywords"
                    content="Hospitality Interior & Design Expo 2024,Book Your Exhibition Stall, Exhibition Stall  Creative Directors , Business Development Directors, Architects, Heads of Marketing , Graphic Designers , Brand Manager , India's leading event, Interior & Design Exhibition, Trade Fair on Furniture, Furniture Trade Fair, Trade Fair on Furnishings, Trade Fair on Interiors Design, Exhibition of Lighting Meterials, Lighting Trade Fair, Surface designing exhibitions " />
                <meta property="twitter:image" content="https://hideindia.com/assets/images/inner-banner/Furnishing_zone.webp" />
                <link rel="canonical" href="https://hideindia.com/furnishing-zone" />
            </Helmet>

            <Header />
            <section class="slider">
                <div class="container-fluid p-0">
                    <img src='/assets/images/inner-banner/Furnishing_zone.webp' alt='' class="bannerimg2 forwebsite" />
                    <img src='/assets/images/banner/mobilebanner/furnishingzone.png.webp' alt='' class="bannerimg2 formobile" />
                    <div class="content">
                        <div class="bannertext" id="banner_text_inner">
                            <h1>Furnishing Zone</h1>
                            <p class="bannerp">Latest Trends of Bed Linen, Curtains, Blinds, Shower
                                Curtains, Pullovers, Bathtrobes, <br />Hand Towels will displayed for the
                                Hospitality industry at HIDE INDIA 2024
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <Category />
            <section class="furnituresection">
                <div class="container-fluid">
                    <div class="title">
                        <h1 className='mt-2'>Furnishings Zone</h1>
                    </div>
                    <div class="row">
                        <div class="col-md-7">
                            <p className='mt-2'>
                                The place to see and touch the furnishings that are enhancing the look and feel of businesses
                                around the world.<br />
                                Choosing the right style for your furnishings is absolutely crucial when you come to kit out or
                                replace the fittings in
                                your establishment. The wrong furnishings can quickly destroy the consistent image that your
                                brand craves and hit your
                                profits in the long run. Everything from the tables to the curtains need to be carefully planned
                                and add to the overall
                                ambience of your establishment.<br />
                                In this zone, visitors will discover the trendiest and most stylish furnishings shaping the way
                                that businesses around
                                the world look, feel and function. Brimming with innovative supplier exhibitors and a plethora
                                of experts in the
                                industry, you’ll see furnishings here that you’ll have seen nowhere else.<br />
                                <b><i>Furnishings Zone</i></b> will exhibit the latest trends and designs of furnishings for the
                                Hospitality industry at <b>HIDE 2024</b>
                            </p>

                        </div>
                        <div class="col-md-5">
                            <ReactPlayer
                                url={Video}
                                playing
                                loop={true}
                                autoplay
                                width="100%"
                                height="100%"
                            />
                        </div>

                    </div>
                </div>


            </section>


            <Footer />







        </>
    )
}

export default FurnishingZone