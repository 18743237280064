
import React from 'react'
import { Helmet } from 'react-helmet'
// import Category from '../Comp/Category'
import Footer from '../../Comp/Footer'
import Header from '../../Comp/Header'


const ExhibitorProfile = () => {
    return (
        <>
            <Helmet>
                <title>Exhibitor Profile | Hide India | Best Exhibition Organizer in India </title>
                <meta name="description"
                    content="The Hospitality Interior & Design Expo 2024 is India's leading event for the designs behind business growth! India's only Exclusive Trade Fair on Furniture, Furnishings, Interiors, Lighting, Design & Surfacing for Hospitality Industry" />
                <meta name="keywords"
                    content="Hospitality Interior & Design Expo 2024, Creative Directors, Graphic Designers , Brand Manager , India's leading event, Interior & Design Exhibition, Trade Fair on Furniture, Furniture Trade Fair, Trade Fair on Furnishings, Trade Fair on Interiors Design, Exhibition of Lighting Meterials, Lighting Trade Fair, Surface designing exhibitions" />

                {/* Og Tags */}
                <meta property="og:url" content="https://hideindia.com/exhibitor_profile" />
                <meta property="og:title" content="Exhibitor Profile | Hide India | Best Exhibition Organizer in India " />
                <meta property="og:description" content="The Hospitality Interior & Design Expo 2024 is India's leading event for the designs behind business growth! India's only Exclusive Trade Fair on Furniture, Furnishings, Interiors, Lighting, Design & Surfacing for Hospitality Industry" />
                <meta name="og:site_name" content="Hospitality Interior & Design Expo 2024" />
                <meta property="og:image" content="https://hideindia.com/assets/images/inner-banner/Bookmystall.png.webp" />

                {/* Twiter Tags */}
                <meta property="twitter:url" content="https://hideindia.com/exhibitor_profile" />
                <meta property="twitter:title" content="Exhibitor Profile | Hide India | Best Exhibition Organizer in India " />
                <meta property="twitter:description" content="The Hospitality Interior & Design Expo 2024 is India's leading event for the designs behind business growth! India's only Exclusive Trade Fair on Furniture, Furnishings, Interiors, Lighting, Design & Surfacing for Hospitality Industry" />
                <meta name="twitter:keywords"
                    content="Hospitality Interior & Design Expo 2024, Creative Directors , Business Development Directors, Architects, Heads of Marketing , Graphic Designers , Brand Manager , India's leading event, Interior & Design Exhibition, Trade Fair on Furniture, Furniture Trade Fair, Trade Fair on Furnishings, Trade Fair on Interiors Design, Exhibition of Lighting Meterials, Lighting Trade Fair, Surface designing exhibitions " />
                <meta property="twitter:image" content="https://hideindia.com/assets/images/inner-banner/Bookmystall.png.webp" />
                <link rel="canonical" href="https://hideindia.com/exhibitor_profile" />
            </Helmet>

            <Header />

            <section class="slider">
                <div class="container-fluid p-0">
                    <img src='/assets/images/inner-banner/Bookmystall.png.webp' alt='' class="bannerimg2 forwebsite" />
                    <img src='/assets/images/banner/mobilebanner/Bookmystall.png.webp' alt='' class="bannerimg2 formobile" />
                    <div class="content">
                        <div class="bannertext">

                        </div>
                    </div>
                </div>
            </section>



            <section class="aboutusection">
                <div class="container-fluid">
                    <div class="title">
                        <h1 style={{ marginBottom: "10px" }} >Exhibitor Profile</h1>

                    </div>
                    <div class="row">
                        <div class="col-md-7">
                            <p style={{ marginTop: "1em" }} >
                                <h2 style={{fontSize:'16px'}}><b>Who Exhibits ?</b></h2>

                                {/* *** Old Data **** */}
                                {/* <p>If you provide any of the following products or services, then you should be exhibiting at HIDE 2023</p> */}

                                {/* *** New data **** */}
                                <p>If you provide any of the following products or services, then you should be exhibiting at <strong>HIDE INDIA 2024</strong></p>
                                <div class="aboutlist d-flex justify-content-lg-between mb-4">
                                    <div className='col-5'>
                                        <ul>
                                            <li>&nbsp;Furniture</li>
                                            <li>&nbsp;Furnishings</li>
                                            <li>&nbsp;Doors & Windows</li>
                                            <li>&nbsp;Signage</li>
                                            <li>&nbsp;Lighting</li>
                                            <li>&nbsp;Interiors</li>
                                            <li>&nbsp;Ceilings</li>
                                        </ul>
                                    </div>
                                    <div className='col-7'>
                                        <ul>
                                            <li>&nbsp;Bathrooms</li>
                                            <li>&nbsp;Flooring</li>
                                            <li>&nbsp;Art</li>
                                            <li>&nbsp;Kitchens</li>
                                            <li>&nbsp;Smart Security Systems</li>
                                            <li>&nbsp;Bar Design</li>
                                            <li>&nbsp;Surface Materials & other products related to the industry</li>
                                        </ul>

                                    </div>
                                </div>
                                {/* <a href={pdf} target='_blank' className='stall_button stall_button-top-nav text-white'>Post Event Report PDF</a> */}
                            </p>
                        </div>
                        <div class="col-md-5">
                            <img src="./assets/images/exhibition.jpg" alt="About us" class="img-fluid aboutusimg pe-xl-2" />
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default ExhibitorProfile