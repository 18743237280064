import "./parallax.css";
export default function ParallaxSection() {
    return (
        <section className="parallax-section">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-10 mx-auto text-white text-center">
                        <div class="title parallax mb-4">
                            <h2 style={{ marginBottom: "10px" }} >Spotlight</h2>

                        </div>
                        <p className="text-white text-center">The <b>MR. & Miss HIDE Model Hunt</b> is a great chance to show your talent and style. If you dream of becoming a model, this event is perfect for you! Take the first step toward your modeling career and make your mark in the fashion world</p>
                    </div>
                    <div className="row justify-content-center">
                        {/* <div className="col-12 d-flex justify-content-center flex-wrap">
                            <div className="stall_button stall_button-top-nav parallax-button book-stall heart stall_button-banner-phnview ">
                                <a href="/seminar-registration" target="_blank">
                                    Book Your Slot for Seminar
                                </a>
                            </div>
                            <div className="stall_button stall_button-top-nav parallax-button">
                                <a href="/events" target="_blank">
                                    Register For Model Hunt
                                </a>
                            </div>
                        </div> */}
                        <div className="col-12 text-center">
                            <ul className='d-flex flex-wrap justify-content-center mt-4 pt-2 gap-4 gap-lg-5'>
                                <li className="book-stall-btn-banner-animation heart">
                                    <span> </span>
                                    <span> </span>
                                    <span> </span>
                                    <span> </span>
                                    <a href='https://forms.gle/hBUbu2kHgyikYPXs9' target="_blank" className='stall_button banner-btn-phn-view-two stall_button-banner-phnview bg-white px-sm-4 py-sm-3 px-2 py-2 fs-sm-4 fs-6' style={{ border: '1px solid #000' }}>Register For Model Hunt</a>
                                </li>
                                <li className="book-stall-btn-banner-animation heart">
                                    <span> </span>
                                    <span> </span>
                                    <span> </span>
                                    <span> </span>
                                    <a href='https://docs.google.com/forms/d/e/1FAIpQLSewDmVxNJ5GAk9CUxz9DHe7o90Jpqkkn2J_ACkhQIU1PnM65w/viewform?fbclid=PAZXh0bgNhZW0CMTEAAaapHnh9qmzzMNqTCdqRF06D8uWdBeE2ZForAqlzqP3WlPTqIjeVyJRfYUo_aem_FS-PEHECciDk3CUBCDZMlw' target="_blank" className='stall_button stall_button-banner-phnview banner-btn-phn-view-two bg-white px-sm-4 py-sm-3 px-2 py-2 fs-sm-4 fs-6' style={{ border: '1px solid #000' }}>Book Your Slot for Seminar</a>
                                </li>

                            </ul>
                        </div>
                        {/* <div className="col-6">
                            <div className="stall_button stall_button-top-nav seminar-button">
                                <a href="https://forms.gle/hBUbu2kHgyikYPXs9" target="_blank">
                                    Register For Model Hunt
                                </a>
                            </div>
                        </div> */}
                    </div>

                </div>
            </div>
        </section>
    )
}