
import React from 'react'

import BooknowPopup from "../Comp/BooknowPopup"

const ShowHighlight = () => {
    return (
        <section class="highlightsection">
            <div class="container-fluid">
                <div class="title">
                    <h2 style={{ marginBottom: "15px" }} >Highlights of the Show</h2>
                </div>
                <div className='row'>
                    <div className='col-lg-6'>
                        <div className='box'>
                            <div className='d-flex'>
                                <img src='../../highlightsicon/ICONS/Keynote-Speakers.png' className='img-responsive img-fluid me-2' />
                                <h3 className='d-flex align-items-center heading_timeline highlight-show-font'>Keynote Speakers</h3>
                            </div>

                            <p>Hear industry experts from the biggest brands on the planet divulge their very own secrets to success, enabling you to return to your business brimming with ideas and ambition.</p>
                        </div>
                    </div>

                    <div className='col-lg-6'>
                        <div className='box'>
                            <div className='d-flex'>
                                <img src='../../highlightsicon/ICONS/Seminar Sessions.png' className='img-responsive img-fluid me-2' />
                                <h3 className='d-flex align-items-center heading_timeline highlight-show-font'>Seminar Sessions</h3>
                            </div>
                            <p>Our seminar sessions are specifically tailored to cover every aspect of the industry. Whether you’re a global chain or an independent, you’re guaranteed to gain invaluable insight into the design ideas forming the trends of tomorrow.</p>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-6'>
                        <div className='box'>
                            <div className='d-flex'>
                                <img src='../../highlightsicon/ICONS/Sourcing Products & Services.png' className='img-responsive img-fluid me-2' />
                                <h3 className='d-flex align-items-center heading_timeline highlight-show-font'>Sourcing Products & Services </h3>
                            </div>
                            <p>This is an incredible opportunity not only to find over 200 of India's top suppliers all under one roof, but also to take advantage of free consultations that will provide you with that crucial competitive edge.</p>
                        </div>
                    </div>

                    <div className='col-lg-6'>
                        <div className='box'>
                            <div className='d-flex'>
                                <img src='../../highlightsicon/ICONS/Thought-Provoking Panel Sessions.png' className='img-responsive img-fluid me-2' />
                                <h3 className='d-flex align-items-center heading_timeline highlight-show-font'>Thought-Provoking Panel Sessions
                                </h3>
                            </div>
                            <p>Our panel debates provide our visitors with a unique opportunity to hear design experts take on a plethora of the industry’s most pressing topics. Each session is jam-packed with information, inspiration and advice, enabling the audience to put their questions to those who have all the answers.</p>
                        </div>
                    </div>
                </div>

                {/* ***** Book Now Popup *****/}
                {/* <div className='col-12'>
                    <ul className='d-flex justify-content-center mt-4 pt-2'>
                        <li className="book-stall-btn-banner-animation heart">
                            <span> </span>
                            <span> </span>
                            <span> </span>
                            <span> </span>
                            <a href='/exhibitor-registration' target="_blank" className='stall_button stall_button-banner-phnview bg-white px-sm-4 py-sm-3 px-2 py-2 fs-sm-4 fs-6' style={{ border: '1px solid #000' }}>Book Now</a>
                        </li>
                    </ul>
                </div> */}
                <BooknowPopup />
            </div>
        </section>
    )
}

export default ShowHighlight;