import { Link } from "react-router-dom";
import logo from "../assets/banner/hidelogo.png";

import Notiflix from "notiflix";
import { useEffect, useState } from "react";
import GetApiCall from "../API/GETAPI";
import PostApiCall from "../API/POSTAPI";
import Graph from "./Graph";
import MultyGraph from "./MultyGraph";
import "./Stats.css";
export default function VisitorDashboard() {
  const [count, setCount] = useState([]);
  const [chartData, setChartData] = useState({
    options: {
      chart: {
        height: 280,
        type: "area",
        stacked: !0,
        toolbar: {
          show: 0,
        },
        zoom: {
          enabled: !0,
        },
      },
      plotOptions: {
        bar: {
          horizontal: !0,
          columnWidth: "10%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: 0,
      },
      xaxis: {
        categories: [],
      },
      legend: {
        position: "bottom",
        show: 0,
      },
      colors: ["#ff6600", "#f7904c"],
      fill: {
        colors: ["#f7904c", "#ff6600"],
      },
      stroke: {
        curve: "smooth",
      },
      total: "",
      responsive: [
        {
          breakpoint: 1000,
          yaxis: {
            categories: [],
          },
          options: {
            plotOptions: {
              bar: {
                horizontal: true,
                columnWidth: "50%",
                endingShape: "rounded",
              },
            },
          },
        },
      ],
    },
    series: [],
  });
  const [chartData1, setChartData1] = useState({
    options: {
      chart: {
        height: 280,
        type: "area",
        stacked: !0,
        toolbar: {
          show: 0,
        },
        zoom: {
          enabled: !0,
        },
      },
      plotOptions: {
        bar: {
          horizontal: !0,
          columnWidth: "10%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: 0,
      },
      xaxis: {
        categories: [],
      },
      legend: {
        position: "bottom",
        show: 0,
      },
      colors: ["#ff6600", "#f7904c"],
      fill: {
        colors: ["#f7904c", "#ff6600"],
      },
      stroke: {
        curve: "smooth",
      },
      total: "",
      responsive: [
        {
          breakpoint: 1000,
          yaxis: {
            categories: [],
          },
          options: {
            plotOptions: {
              bar: {
                horizontal: true,
                columnWidth: "50%",
                endingShape: "rounded",
              },
            },
          },
        },
      ],
    },
    series: [],
  });
  const [chartData2, setChartData2] = useState({
    options: {
      chart: {
        height: 280,
        type: "area",
        stacked: !0,
        toolbar: {
          show: 0,
        },
        zoom: {
          enabled: !0,
        },
      },
      plotOptions: {
        bar: {
          horizontal: !0,
          columnWidth: "10%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: 0,
      },
      xaxis: {
        categories: [],
      },
      legend: {
        position: "bottom",
        show: 0,
      },
      colors: ["#ff6600", "#f7904c"],
      fill: {
        colors: ["#f7904c", "#ff6600"],
      },
      stroke: {
        curve: "smooth",
      },
      total: "",
      responsive: [
        {
          breakpoint: 1000,
          yaxis: {
            categories: [],
          },
          options: {
            plotOptions: {
              bar: {
                horizontal: true,
                columnWidth: "50%",
                endingShape: "rounded",
              },
            },
          },
        },
      ],
    },
    series: [],
  });
  useEffect(() => {
    GetApiCall.getRequest("GetDashboardVisitorCount").then((resultscity) =>
      resultscity.json().then((obj) => {
        if (resultscity.status == 200 || resultscity.status == 201) {
          setCount(obj.data);
        }

        Notiflix.Loading.remove();
      })
    );
    fetchGraphData("2024-09-28 10:00:00", "2024-09-28 19:00:00", setChartData1); // Graph 1
    fetchGraphData("2024-09-27 10:00:00", "2024-09-27 19:00:00", setChartData); // Graph 3
    fetchGraphData('2024-09-29 10:00:00', '2024-09-29 19:00:00', setChartData2); // Graph 4
    // setTimeout(() => {
    //   fetchGraphData("2024-09-27 10:00:00", "2029-09-27 19:00:00", setChartData2); // Graph 2
    // }, 1000);
  }, []);
  const fetchGraphData = (fromDate, toDate, setChartData) => {
    PostApiCall.postRequest({ fromDate, toDate }, "GetDashboardVisitors")
      .then((response) => response.json())
      .then((obj) => {
        if (obj.data.length !== 0) {
          const months = [];
          const values = [];
          var total = 0;
          obj.data.forEach((data) => {
            months.push(data.slot_time);
            values.push(data.visitor_count);
            total = total + data.visitor_count;
          });

          // Update the state of the specific graph
          setChartData((prev) => ({
            ...prev,
            options: {
              ...prev.options,
              xaxis: { categories: months },
              total: total,
            },
            series: [{ name: fromDate?.split(' ')[0], data: values }],
          }));
        }
        Notiflix.Loading.remove();
      });
  };

  return (
    <>
      <div className="d-flex w-100 justify-content-center bg-white shadow-sm pb-1">
        <img
          //   className="col-11"
          src={logo}
          width="87%"
          style={{ borderRadius: "7px", marginTop: "10px" }}
        />
      </div>
      <div className="container-fluid mt-4 mb-5 pb-3 stats-page">
        <div className="row">
          <div className="col-6 pe-1">
            <Link to="/visitor-dashboard" className={window.location.pathname == '/visitor-dashboard' ? "top-btn active" : 'top-btn'}>
              Visitor Count
            </Link>
          </div>
          <div className="col-6 ps-1">
            <Link to="/visitor-list" className={window.location.pathname == '/visitor-list' ? "top-btn active" : 'top-btn'}>
              Visitor List
            </Link>
          </div>
          <div className="col-12 mt-2">
            <Link to="/pre-registered-list" className={window.location.pathname == '/pre-registered-list' ? "top-btn active" : 'top-btn'}>
              Pre Registered List
            </Link>
          </div>
          <div className="col-12 mt-4">
            <div className="scroll-card-box">
              <div className="scroll-card">
                <div className="scroll-card-icon">
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 32 32"
                    height="200px"
                    width="200px"
                    xmlns="http://www.w3.org/2000/svg">
                    <path d="M 7.5 4 C 5.578 4 4 5.578 4 7.5 C 4 9.422 5.578 11 7.5 11 C 9.422 11 11 9.422 11 7.5 C 11 5.578 9.422 4 7.5 4 z M 12 4 L 12 11.84375 C 12.232 12.12875 12.443281 12.432484 12.613281 12.771484 L 13.568359 14.683594 L 14 15.005859 L 14 6 L 15.132812 6 L 16.982422 18.947266 L 16.029297 22.757812 L 16 23.5 C 16 24.879 17.121 26 18.5 26 C 19.141 26 19.719109 25.750516 20.162109 25.353516 C 20.672109 25.751516 21.304 26 22 26 C 22.771 26 23.468 25.699703 24 25.220703 C 24.532 25.700703 25.229 26 26 26 C 26.352 26 26.686 25.928406 27 25.816406 L 27 28 L 29 28 L 29 4 L 28 4 L 27 4 L 14 4 L 13 4 L 12 4 z M 7.5 6 C 8.34 6 9 6.66 9 7.5 C 9 8.34 8.34 9 7.5 9 C 6.66 9 6 8.34 6 7.5 C 6 6.66 6.66 6 7.5 6 z M 17.152344 6 L 27 6 L 27 23 C 27 23.552 26.552 24 26 24 C 25.448 24 25 23.552 25 23 L 23 23 C 23 23.552 22.552 24 22 24 C 21.448 24 21 23.552 21 23 L 19 23 L 19 23.5 C 19 23.771 18.771 24 18.5 24 C 18.229 24 18 23.771 18 23.5 L 18 23.123047 L 19.017578 19.052734 L 17.152344 6 z M 7 12 C 4.793 12 3 13.793 3 16 L 3 21.25 L 5 22.25 L 5 28 L 7 28 L 7 21 L 5 20 L 5 16 C 5 14.898 5.898 14 7 14 L 8.15625 14 C 8.91825 14 9.5975 14.40975 9.9375 15.09375 L 11.21875 17.65625 L 13.867188 19.628906 L 15 18 L 12.78125 16.34375 L 11.71875 14.21875 C 11.03475 12.85475 9.68025 12 8.15625 12 L 7 12 z M 12 20.484375 L 12 28 L 14 28 L 14 21.972656 L 13.271484 21.429688 L 12 20.484375 z M 8 21 L 8 28 L 10 28 L 10 21 L 8 21 z"></path>
                  </svg>
                </div>
                <div>
                  <h5>On-site Visitors</h5>
                  <p>{count[0]?.onsite_visitors}</p>
                </div>
              </div>
              <div className="scroll-card">
                <div className="scroll-card-icon">
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 256 256"
                    height="200px"
                    width="200px"
                    xmlns="http://www.w3.org/2000/svg">
                    <path d="M232.49,55.51l-32-32a12,12,0,0,0-17,0l-96,96A12,12,0,0,0,84,128v32a12,12,0,0,0,12,12h32a12,12,0,0,0,8.49-3.51l96-96A12,12,0,0,0,232.49,55.51ZM192,49l15,15L196,75,181,60Zm-69,99H108V133l56-56,15,15Zm105-7.43V208a20,20,0,0,1-20,20H48a20,20,0,0,1-20-20V48A20,20,0,0,1,48,28h67.43a12,12,0,0,1,0,24H52V204H204V140.57a12,12,0,0,1,24,0Z"></path>
                  </svg>
                </div>
                <div>
                  <h5>Pre Registered (Show Directory)</h5>
                  <p>{count[0]?.online_visitors}</p>
                </div>
              </div>
              <div className="scroll-card">
                <div className="scroll-card-icon">
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 640 512"
                    height="200px"
                    width="200px"
                    xmlns="http://www.w3.org/2000/svg">
                    <path d="M192 96a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm-8 384V352h16V480c0 17.7 14.3 32 32 32s32-14.3 32-32V192h56 64 16c17.7 0 32-14.3 32-32s-14.3-32-32-32H384V64H576V256H384V224H320v48c0 26.5 21.5 48 48 48H592c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48H368c-26.5 0-48 21.5-48 48v80H243.1 177.1c-33.7 0-64.9 17.7-82.3 46.6l-58.3 97c-9.1 15.1-4.2 34.8 10.9 43.9s34.8 4.2 43.9-10.9L120 256.9V480c0 17.7 14.3 32 32 32s32-14.3 32-32z"></path>
                  </svg>
                </div>
                <div>
                  <h5>Pre Registered (Webiste)</h5>
                  <p>{count[0]?.pre_registrations}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 mt-4">
            <div className="border bottom-border">
              <div className="mid-card">
                <b>Visitor Summary</b>
              </div>
              <div className="graph-card">
                <MultyGraph chartData={chartData} chartData1={chartData1} chartData2={chartData2} />
              </div>
            </div>
          </div>
          <div className="col-12 mt-4">
            <div className="border bottom-border">
              <div className="mid-card">
                <b>27th September 2024</b>
                <div className="mid-card-content">
                  <strong>Total Visitors - {chartData?.options?.total}</strong>
                </div>
              </div>
              <div className="graph-card">
                <strong>Hour Wise Visitors</strong>
                <Graph chartData1={chartData} />
              </div>
            </div>
          </div>
          <div className="col-12 mt-4">
            <div className="border bottom-border">
              <div className="mid-card">
                <b>28th September 2024</b>
                <div className="mid-card-content">
                  <strong>Total Visitors - {chartData1?.options?.total}</strong>
                </div>
              </div>
              <div className="graph-card">
                <strong>Hour Wise Visitors</strong>
                <Graph chartData1={chartData1} />
              </div>
            </div>
          </div>
          <div className="col-12 mt-4">
            <div className="border bottom-border">
              <div className="mid-card">
                <b>29th September 2024</b>
                <div className="mid-card-content">
                  <strong>Total Visitors - {chartData2?.options?.total}</strong>
                </div>
              </div>
              <div className="graph-card">
                <strong>Hour Wise Visitors</strong>
                <Graph chartData1={chartData2} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
