
import React from 'react'
import { Helmet } from 'react-helmet'
// import Category from '../Comp/Category'
import Footer from '../Comp/Footer'
import Header from '../Comp/Header'

const About = () => {
    return (
        <>
            <Helmet>
                <title>About us | HIDE India | India's leading Exhibition Organizer</title>
                <meta name="description"
                    content="We have 20 years of experience in India's ever evolving marketing landscape, Over 550 events produced and conceptualized over last 2 decades" />
                <meta name="keywords"
                    content="Hospitality Interior & Design Expo 2024, marketing landscape, marketing , India's leading event, Interior & Design Exhibition, Trade Fair on Furniture, Furniture Trade Fair, Trade Fair on Furnishings, Trade Fair on Interiors Design, Exhibition of Lighting Meterials, Lighting Trade Fair, Surface designing exhibitions" />

                {/* Og Tags */}
                <meta property="og:url" content="https://hideindia.com/about" />
                <meta property="og:title" content="About us | HIDE India | India's leading Exhibition Organizer" />
                <meta property="og:description" content="We have 20 years of experience in India's ever evolving marketing landscape, Over 550 events produced and conceptualized over last 2 decades" />
                <meta name="og:site_name" content="Hospitality Interior & Design Expo 2024" />
                <meta property="og:image" content="https://hideindia.com/assets/images/inner-banner/aboutus.webp" />

                {/* Twiter Tags */}
                <meta property="twitter:url" content="https://hideindia.com/about" />
                <meta property="twitter:title" content="About us | HIDE India | India's leading Exhibition Organizer" />
                <meta property="twitter:description" content="We have 20 years of experience in India's ever evolving marketing landscape, Over 550 events produced and conceptualized over last 2 decades" />
                <meta name="twitter:keywords"
                    content="Hospitality Interior & Design Expo 2024,  marketing landscape, marketing , India's leading event, Interior & Design Exhibition, Trade Fair on Furniture, Furniture Trade Fair, Trade Fair on Furnishings, Trade Fair on Interiors Design, Exhibition of Lighting Meterials, Lighting Trade Fair, Surface designing exhibitions " />
                <meta property="twitter:image" content="https://hideindia.com/assets/images/inner-banner/aboutus.webp" />
                <link rel="canonical" href="https://hideindia.com/about" />
            </Helmet>
            <Header />

            <section class="slider">
                <div class="container-fluid p-0">
                    <img src='/assets/images/inner-banner/aboutus.webp' alt='' class="bannerimg2 forwebsite" />
                    <img src='/assets/images/banner/mobilebanner/aboutusbanner.webp' alt='' class="bannerimg2 formobile" />
                    <div class="content">
                        <div class="bannertext">

                        </div>
                    </div>
                </div>
            </section>




            <section class="aboutusection">
                <div class="container-fluid">
                    <div class="title">
                        <h1 style={{ marginBottom: "10px" }} >About Us</h1>

                    </div>
                    <div class="row">
                        <div class="col-md-7">
                            <p style={{ marginTop: "1em" }} >
                                <b>INTRODUCTION TRADE SHOWS PVT LTD</b><br />

                                <b><i>Since 1994</i></b> <br />

                                <span>
                                    <h2 class="subheading about-subheading">Who are we</h2>
                                </span>
                                <p>Introductions Trade Shows Group is a multi-faceted award winning company specializing in event
                                    production, event
                                    management, organizing mega B2B trade fairs in domestic & international markets</p>
                                <div class="aboutlist">
                                    <ul>
                                        <li>&nbsp;20 years of experience in India's ever evolving marketing landscape</li>
                                        <li>&nbsp;Over 550 events produced and conceptualized since last 2 decades</li>
                                        <li>&nbsp;Presence in Asian, Europe & African Markets</li>
                                        <li>&nbsp;Results assured with focus driven marketing efforts</li>
                                        <li>&nbsp;Complete Guidance for the client from inception to completion</li>

                                    </ul>
                                </div>
                            </p>
                            <br />
                            <p>
                                <span>
                                    <h2 class="subheading about-subheading">Our Work</h2>
                                </span>
                                Domestic Clients & Markets covered :<br />
                                <div class="aboutlist">
                                    <ul>
                                        <li>&nbsp;Founders of India Big 7- Mega B2B Trade Fair on Gifts, Housewares, Novelties,
                                            Foods, Stationery grown over last 14 years
                                            (Now Sold to Reed Exhibitions)</li>
                                        <li>&nbsp;IFEX (India Furniture Expo) Organized in association with AFMT ( Association of
                                            Furniture Manufacturers & Traders India
                                            ) B2B Trade Fair on Interiors annually</li>
                                        <li>&nbsp;Owned IP's of B2C & B2B Exhibitions, The Education Show, Health & Wellness Tarde
                                            Shows, Thailand Trade Festival,
                                            Furniture & Home Decor Fair, Home Fair, Goa Property Fair, 2nd Homes Fair, Fashion
                                            Jalsa, Asia Jewels Fair, Asia
                                            Wedding Fair, Kidz World, The Culture Collection,
                                        </li>
                                    </ul>
                                </div>

                            </p>
                            <br />
                            <p>
                                <span>
                                    <h2 class="subheading about-subheading">International Clients & Markets covered</h2>
                                </span>
                                <div class="aboutlist">
                                    <ul>
                                        <li>&nbsp;Property Fairs in Thailand, Hongkong, Singapore, Belgium, Kenya, Indonesia, UK for
                                            last 11 years</li>
                                        <li>&nbsp;Education Fairs in Srilanka, Thailand, Nepal, Maldives, Jakarta for last 8 years ,
                                            Fashion Events in Thailand, Indonesia, Kenya. </li>
                                    </ul>

                                </div>
                            </p>

                            <p style={{ fontSize: '20px', fontWeight: '600', marginTop: '10px', paddingLeft: '10px' }}>To Know More About Us Click Here <a href='http://infairs.co.in/' target={'blank'} style={{ color: "#F7904C", marginLeft: "20px" }}>www.infairs.co.in <img src="/assets/images/introductionlogo.png" /></a></p>


                        </div>
                        <div class="col-md-5">
                            <img src="./assets/images/Page10.jpg.webp" alt="About us" class="img-fluid aboutusimg pe-xl-2" />
                        </div>

                    </div>
                </div>


            </section>

            <Footer />
        </>
    )
}

export default About