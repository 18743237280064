import QRCode from 'qrcode';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import logo from "../assets/banner/hidelogo.png";
import BottomNavbar from "./BottomNavbar";
import "./Showdirectory.css";
export default function TicketPage() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  // useEffect(() => {
  //   if (window.innerWidth > 500) {
  //     window.location.href = "/";
  //   }
  // }, []);
  const [qrUrl, setQrUrl] = useState("");
  var customerdata = localStorage.getItem("CustomData")
  var customerdetails = JSON.parse(customerdata)
  QRCode.toDataURL(`${customerdetails[0]?.newid}`).then((url) => {
    setQrUrl(url);
  })
  return (
    <>
      <div className="d-flex w-100 justify-content-center bg-white">
        <img
          //   className="col-11"
          src={logo}
          width="87%"
          style={{ borderRadius: "7px", marginTop: "10px" }}
        />
      </div>
      <div class="container-fluid qr-code-page py-4 mb-5">
        <div className="row">
          <div className="col-12 d-none">
            <div className="qr-code-box">
              <div className="qr-code-img">
                <p className="fs-1 text-dark mb-2">Visitor</p>
                <img src={qrUrl} className="img-fluid" />
                <p className="fw-bold fs-4 text-dark mt-2">{customerdetails[0]?.newid}</p>
              </div>
              <div className="qr-content">
                <div className="divider">
                  <span></span>
                </div>
                {/* <div className="qr-box">
                  <span>Id Number</span>
                  <p>12345</p>
                </div> */}
                <div className="qr-box">
                  <span>Name</span>
                  <p>{customerdetails[0]?.name}</p>
                </div>
                <div className="qr-box">
                  <span>Email</span>
                  <p>
                    <a href="mailto:">{customerdetails[0]?.email}</a>
                  </p>
                </div>
                <div className="qr-box">
                  <span>Phone Number</span>
                  <p>
                    <a href={`tel:+91${customerdetails[0]?.mobile}`}>+91 {customerdetails[0]?.mobile}</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-10 mx-auto mt-3">
            {/* <div class="id-card-tag"></div>
          <div class="id-card-tag-strip"></div> */}
            <div class="id-card-hook"></div>

            <div class="id-card-holder">
              <div class="id-card">
                {/* <div class="header">
                  <img src={logo} className="img-fluid" />
                </div> */}
                <h3>Visitor</h3>
                <div class="photo">
                  <img src={qrUrl} className="img-fluid" />
                </div>
                {/* <div class="qr-code"></div> */}
                <h5>{customerdetails[0]?.newid}</h5>
                <div className="qr-content">
                  <div className="divider"></div>
                  <div className="qr-box">
                    <span>Name:</span>
                    <p>{customerdetails[0]?.name}</p>
                  </div>
                  <div className="qr-box">
                    <span>Email:</span>
                    <p>
                      <a href="mailto:">{customerdetails[0]?.email}</a>
                    </p>
                  </div>
                  <div className="qr-box">
                    <span>Phone Number:</span>
                    <p>
                      <a href={`tel:+91${customerdetails[0]?.mobile}`}>+91 {customerdetails[0]?.mobile}</a>
                    </p>
                  </div>
                </div>
                {/* <p>
                  <strong>NIIT University</strong> Neemrana, NH-8 Delhi-Jaipur
                  highway{" "}
                </p>
                <p>
                  District Alwar, Rajasthan <strong>301705</strong>
                </p>
                <p>Ph: 01494-660600, 7073222393</p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <BottomNavbar />
    </>
  );
}
