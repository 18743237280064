
import React from 'react';
import Category from '../Comp/Category';
import Footer from '../Comp/Footer'
import Header from '../Comp/Header';
import Video from '../assets/video/furniture.mp4';
import ReactPlayer from 'react-player/lazy'
import { Helmet } from 'react-helmet';

const FurnitureZone = () => {
    return (

        <>
            <Helmet>
                <title>Furniture Exhibition Zone | Hospitality | Hide India Expo 2024 </title>
                <meta name="description"
                    content="Furniture has a big impact on the look and feel of an interior. While its main purpose is functionality, an appealing aesthetic is also crucial." />
                <meta name="keywords"
                    content="Hospitality Interior & Design Expo 2024,Book Your Exhibition Stall, Exhibition Stall  Creative Directors , Business Development Directors, Architects, Heads of Marketing , Graphic Designers , Brand Manager , India's leading event, Interior & Design Exhibition, Trade Fair on Furniture, Furniture Trade Fair, Trade Fair on Furnishings, Trade Fair on Interiors Design, Exhibition of Lighting Meterials, Lighting Trade Fair, Surface designing exhibitions" />

                {/* Og Tags */}
                <meta property="og:url" content="https://hideindia.com/furniture-zone" />
                <meta property="og:title" content="Furniture Exhibition Zone | Hospitality | Hide India Expo 2024 " />
                <meta property="og:description" content="Furniture has a big impact on the look and feel of an interior. While its main purpose is functionality, an appealing aesthetic is also crucial." />
                <meta name="og:site_name" content="Hospitality Interior & Design Expo 2024" />
                <meta property="og:image" content="https://hideindia.com/assets/images/inner-banner/Furniture_zone.webp" />

                {/* Twiter Tags */}
                <meta property="twitter:url" content="https://hideindia.com/furniture-zone" />
                <meta property="twitter:title" content="Furniture Exhibition Zone | Hospitality | Hide India Expo 2024 " />
                <meta property="twitter:description" content="Furniture has a big impact on the look and feel of an interior. While its main purpose is functionality, an appealing aesthetic is also crucial." />
                <meta name="twitter:keywords"
                    content="Hospitality Interior & Design Expo 2024,Book Your Exhibition Stall, Exhibition Stall  Creative Directors , Business Development Directors, Architects, Heads of Marketing , Graphic Designers , Brand Manager , India's leading event, Interior & Design Exhibition, Trade Fair on Furniture, Furniture Trade Fair, Trade Fair on Furnishings, Trade Fair on Interiors Design, Exhibition of Lighting Meterials, Lighting Trade Fair, Surface designing exhibitions " />
                <meta property="twitter:image" content="https://hideindia.com/assets/images/inner-banner/Furniture_zone.webp" />
                <link rel="canonical" href="https://hideindia.com/furniture-zone" />
            </Helmet>
            <Header />

            <section class="slider ">
                <div class="container-fluid p-0">
                    <img src='/assets/images/inner-banner/Furniture_zone.webp' alt='' class="bannerimg2 forwebsite" />
                    <img src='/assets/images/banner/mobilebanner/furniturezone.png.webp' alt='' class="bannerimg2 formobile" />
                    <div class="content">
                        <div class="bannertext" id="banner_text_inner">
                            <h1>Furniture Zone</h1>
                            <p class="bannerp">Impactful Furniture &amp; Fittings for Guestrooms, Bars,
                                Restuarants, Lobby, Spa's,<br /> Wellness Centers and more... Furniture
                                Zone will exhibit the latest trends and <br />designs of furniture for the
                                Hospitality industry at HIDE INDIA 2024
                            </p>
                        </div>

                    </div>
                </div>
            </section>



            <Category />


            <section class="furnituresection">
                <div class="container-fluid">
                    <div class="title">
                        <h1 className='mt-2'>Furniture Zone</h1>
                    </div>
                    <div class="row">
                        <div class="col-md-7">
                            <p className='mt-2'>
                                Furniture has a big impact on the look and feel of an interior. While its main purpose is
                                functionality, an appealing
                                aesthetic is also crucial. From cozy, intimate lounges to light, open-plan atriums, the
                                furniture that hotel designers
                                choose creates a lasting first impression.<br />
                                Guests who have traveled long distances require a relaxing environment from the moment they
                                enter the lobby to the
                                minute they rest their heads on their pillows. In between business meetings or sightseeing
                                excursions, they often seek
                                exciting leisure options and beautiful dining areas. The options for creativity are limitless,
                                but décor must be chosen
                                with the hotel’s concept in mind.<br />
                                Furniture trends are constantly evolving, in line with wider interior design tastes and
                                preferences. More materials are
                                available than ever before, inspiring furniture designers to produce imaginative new ideas or
                                add modern twists to
                                classic motifs. There are a plethora of furnishing options—from the big, eye-catching statement
                                piece to the subtle
                                finishing touch—all of which help define the theme and style of a venue.<br />
                                <b><i>Furniture Zone</i></b> will exhibit the latest trends and designs of furniture for the
                                Hospitality industry at <b>HIDE 2024</b>
                            </p>

                        </div>

                        <div class="col-md-5 ">

                            <ReactPlayer
                                url={Video}
                                playing
                                loop={true}
                                autoplay
                                width="100%"
                                height="100%"
                            />

                        </div>

                    </div>
                </div>


            </section>


            <Footer />

        </>
    )
}

export default FurnitureZone