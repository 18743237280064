
import React from 'react'

const ShowAbout = () => {
    return (
        <section class="aboutsection" id="about">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12 col-lg-12 col-xl-6">
                        <div class="title formobile">
                            <h2>About the Show</h2>
                        </div>
                        <img src="/assets/images/aboutshow.png.webp" class="aboutimg" />
                    </div>

                    <div class="col-md-12 col-lg-12 col-xl-6">
                        <div class="title forwebsite">
                            <h2>About the Show</h2>
                        </div>
                        <p class="overviewtext mt-3">Exhibiting is one of the most efficient, effective and successful marketing
                            activities available to you. Having a presence at HIDE INDIA 2024 will give
                            you   exposure   to   more   than   3,000   highly   targeted   hospitality   and   design
                            professionals, all hungry for growth and actively looking to find the very best
                            in design to transform the look and feel of their businesses. <br />
                            Exhibit at the
                            2024 event to be a part of this unprecedented experience which is curated to
                            elevate the industry’s most exciting visionaries and unite the biggest names
                            in interior design with the most ambitious hospitality professionals.</p>

                        <h3 class="aboutheading">Who Exhibits?</h3>
                        <p>If you provide any of the following products or services, then you should be exhibiting at
                            HIDE 2024</p>

                        <div class="aboutlist">
                            <ul style={{ fontSize: "14px" }} >
                                <li>&nbsp;Furniture</li>
                                <li>&nbsp;Furnishings</li>
                                <li>&nbsp;Doors & Windows</li>
                                <li>&nbsp;Signage</li>
                                <li>&nbsp;Lighting</li>
                                <li>&nbsp;Interiors</li>
                                <li>&nbsp;Ceilings</li>

                            </ul>
                            <ul class="ullist2">
                                <li>&nbsp;Bathrooms</li>
                                <li>&nbsp;Flooring</li>
                                <li>&nbsp;Art</li>
                                <li>&nbsp;Kitchens</li>
                                <li>&nbsp;Smart Security Systems</li>
                                <li>&nbsp;Bar Design</li>
                                <li>&nbsp;Surface Materials & other products related to the industry</li>
                            </ul>
                        </div>

                    </div>


                </div>
            </div>
        </section>
    )
}

export default ShowAbout