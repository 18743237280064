
import React from 'react'
import Image1 from '../assets/banner/image1.png.webp'
import Image2 from '../assets/banner/image2.png.webp'
import Image3 from '../assets/banner/image3.png.webp'
import Image4 from '../assets/banner/image4.png.webp'
import Image5 from '../assets/banner/image5.png.webp'
import "./category.css";

const Category = () => {
    return (

        <div className='container-fluid  pt-5'>
            <div className='row px-lg-2 d-flex justify-content-between'>
                <div className='col-lg-2 mb-4'>
                    <div className='category_card'>
                        <a href='/furniture-zone' style={{ color: '#000' }}>
                            <img src={Image1} className='img-responsive img-fluid' width={'100%'} />
                            <div className='card_text py-3'>
                                <h3>Furniture Zone</h3>
                            </div>
                        </a>
                    </div>
                </div>

                <div className='col-lg-2 mb-4'>
                    <div className='category_card'>
                        <a href='/furnishing-zone' style={{ color: '#000' }}>
                            <img src={Image2} className='img-responsive img-fluid' width={'100%'} />
                            <div className='card_text py-3'>
                                <h3>Furnishings Zone</h3>
                            </div>
                        </a>
                    </div>
                </div>

                <div className='col-lg-2 mb-4'>
                    <div className='category_card'>
                        <a href='/alfresco-zone' style={{ color: '#000' }}>
                            <img src={Image3} className='img-responsive img-fluid' width={'100%'} />
                            <div className='card_text py-3'>
                                <h3>Al Fresco Zone</h3>
                            </div>
                        </a>
                    </div>
                </div>

                <div className='col-lg-2 mb-4'>
                    <div className='category_card'>
                        <a href='/Lighting-zone' style={{ color: '#000' }}>
                            <img src={Image4} className='img-responsive img-fluid' width={'100%'} />
                            <div className='card_text py-3'>
                                <h3>Lighting Zone</h3>
                            </div>
                        </a>
                    </div>
                </div>

                <div className='col-lg-2'>
                    <div className='category_card'>
                        <a href='/Surfacing-zone' style={{ color: '#000' }}>
                            <img src={Image5} className='img-responsive img-fluid' width={'100%'} />
                            <div className='card_text py-3'>
                                <h3>Surfacing Zone</h3>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>





    )
}

export default Category