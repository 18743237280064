import { PlusOutlined } from '@ant-design/icons';
import { Image, Upload } from 'antd';
import axios from 'axios';
import Notiflix from 'notiflix';
import React, { useEffect, useState } from 'react';
import GetApiCall from '../API/GETAPI';
import PostApiCall from '../API/POSTAPI';
import './ExibitorDetails.css';
import Footer from './Footer';
import Header from './Header';

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

const ExhibitorDetails = () => {
    const [imageUrl, setImageUrl] = useState();
    const [fileList, setFileList] = useState([]);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState("");
    const [urlData, setUrlData] = useState([]);
    const [companyName, setCompanyName] = useState("");
    const [contactPerson, setContactPerson] = useState("");
    const [contactPersonMobile, setContactPersonMobile] = useState("");
    const [email, setEmail] = useState("")
    const [area, setArea] = useState("");
    const [stallNo, setStallNo] = useState("");
    const [productCategory, setProductCategory] = useState("");
    const [address, setAddress] = useState("");
    const [website, setWebsite] = useState("");
    const [companyDescription, setCompanyDescription] = useState("");
    const [contactNo, setContactNo] = useState("");
    const [industry, setIndustry] = useState("");
    const [industryList, setIndustryList] = useState([]);

    useEffect(() => {
        GetIndustryList()
    }, [])

    const uploadButton = (
        <button
            style={{
                border: 0,
                background: "none",
            }}
            type="button"
        >
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </button>
    );
    const validateMobile = (input) => {
        const regex = /^\d{0,10}$/;
        return regex.test(input);
    }

    const onaction = async (dat) => {
        const formData = new FormData();
        formData.append("filename", dat.file.name);
        formData.append("foldername", "ExhibitorImages");
        formData.append("file", dat.file);
        await axios
            .post("https://admin.hideindia.com/hide-api/Add_Image", formData)
            // .post("http://localhost:8094/hide-api/Add_Image", formData)
            .then((res) => {
                setUrlData([...urlData, res.data.image]);
            })
            .catch((err) => {
                if (err.response) {
                    console.log("Error Response:", err.response.data);
                    console.log("Error Status:", err.response.status);
                    console.log("Error Headers:", err.response.headers);
                } else if (err.request) {
                    console.log("Error Request:", err.request);
                } else {
                    console.log("Error Message:", err.message);
                }
                console.log("Error Config:", err.config);
            });
    };


    const onPostExhibitorImages = (id, arr) => {
        PostApiCall.postRequest(
            {
                exhibitorid: id,
                imageurl: arr,
            },
            "AddExhibitorImages"
        ).then((result) =>
            result.json().then((obj) => {
                if (result.status == 200 || result.status == 201) {
                    Notiflix.Loading.remove();
                    // Notiflix.Notify.success("Images successfully uploaded");
                } else {
                    Notiflix.Loading.remove();
                    Notiflix.Notify.failure(obj.data);
                }
            })
        );
    };

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
    };

    const handleChange = async ({ file, fileList }) => {
        const updatedFileList = [...fileList];
        const index = updatedFileList.findIndex((item) => item.uid === file.uid);
        if (index !== -1) {
            updatedFileList[index] = { ...file, status: "done" };
            setFileList(updatedFileList);
        }
        if (file.status === "done") {
            updatedFileList[index] = { ...file, status: "done" };
            setFileList(updatedFileList);
        } else if (file.status === "error") {
            updatedFileList[index] = { ...file, status: "error" };
            setFileList(updatedFileList);
        } else if (file.status === "removed") {
            setFileList(updatedFileList.filter((item) => item.uid !== file.uid));
        }
    };



    const submitExhibitorDetails = () => {
        // Notiflix.Loading.dots("...")
        if (companyName === "") {
            Notiflix.Loading.remove();
            Notiflix.Notify.failure("Company name cannot be empty");
            return;
        }
        if (contactPerson === "") {
            Notiflix.Loading.remove();
            Notiflix.Notify.failure("Contact person name cannot be empty");
            return;
        }
        if (contactPersonMobile == "") {
            Notiflix.Loading.remove();
            Notiflix.Notify.failure("Contact person mobile number cannot be empty");
            return;
        }
        else if (!validateMobile(contactPersonMobile)) {
            Notiflix.Loading.remove()
            Notiflix.Notify.failure("Contact person mobile number is invalid")
            return
        }
        // if (contactNo == "") {
        //     Notiflix.Loading.remove();
        //     Notiflix.Notify.failure("Contact number cannot be empty");
        //     return;
        // } else if (!validateMobile(contactNo)) {
        //     Notiflix.Loading.remove()
        //     Notiflix.Notify.failure("Contact number is invalid")
        // }
        if (email === "") {
            Notiflix.Loading.remove();
            Notiflix.Notify.failure("Enter a valid email id");
            return;
        } else if (!email.includes("@")) {
            Notiflix.Loading.remove()
            Notiflix.Notify.failure("Email is invalid")
            return
        }
        // if (area === "") {
        //     Notiflix.Loading.remove();
        //     Notiflix.Notify.failure("Area cannot be empty");
        //     return;
        // }
        if (stallNo === "") {
            Notiflix.Loading.remove();
            Notiflix.Notify.failure("Stall number cannot be empty");
            return;
        }
        // if (industry === "") {
        //     Notiflix.Loading.remove();
        //     Notiflix.Notify.failure("Please select industry from the dropdown");
        //     return;
        // }
        if (address === "") {
            Notiflix.Loading.remove();
            Notiflix.Notify.failure("Address cannot be empty");
            return;
        }
        // if (website === "") {
        //     Notiflix.Loading.remove();
        //     Notiflix.Notify.failure("");
        //     return;
        // }
        // if (companyDescription === "") {
        //     Notiflix.Loading.remove();
        //     Notiflix.Notify.failure("Company and product profile cannot be empty");
        //     return;
        // }
        if (urlData.length == 0) {
            Notiflix.Loading.remove();
            Notiflix.Notify.failure("Please upload atleast one image");
            return;
        }
        validateExhibitorDetails();
    }

    const clearDeatilForm = () => {
        setCompanyName("")
        setContactPerson("")
        setContactPersonMobile("")
        setEmail("")
        setArea("")
        setStallNo("")
        setAddress("")
        setWebsite("")
        setCompanyDescription("")
        setContactNo("")
        setIndustry("")
        setUrlData([])
        // window.location.reload();
        Notiflix.Loading.remove()
    }

    const validateExhibitorDetails = () => {
        Notiflix.Loading.dots()
        PostApiCall.postRequest({
            id: null,
            companyname: companyName,
            contactperson: contactPerson,
            contactpersonMobile: contactPersonMobile,
            email: email,
            area: area,
            stallno: stallNo,
            address: address,
            website: website,
            companydescription: companyDescription,
            contactno: contactNo,
            industry: industry,
        }, 'updateExhibitorDeatils').then((results) =>
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    Notiflix.Loading.remove();
                    Notiflix.Notify.success("Details successfully Submitted.");
                    // clearDeatilForm();
                    if (urlData.length) {
                        onPostExhibitorImages(obj.data[0].fld_id, urlData);
                    }
                    setTimeout(() => {
                        window.location.reload();
                    }, 3000);
                } else {
                    Notiflix.Loading.remove();
                    Notiflix.Notify.failure(obj.data);
                }
            })
        ).catch((err) => {
            console.log(err)
            Notiflix.Loading.remove();

        })
    }

    const GetIndustryList = () => {
        GetApiCall.getRequest("getIndustryList").then((result) => {
            result.json().then((obj) => {
                setIndustryList(obj.data);
            });
        });
    };

    return (
        <>
            <Header />
            <section class="slider ">
                <div class="container-fluid p-0">
                    <img src='/assets/images/inner-banner/whoshouldvisit.webp' alt='' class="bannerimg2 forwebsite" />

                    <img src='/assets/images/banner/mobilebanner/whoshouldvisit.webp' alt='' class="bannerimg2 formobile" />
                    <div class="content">
                        <div class="bannertext">

                        </div>

                    </div>
                </div>
            </section>
            <div className='title px-3 my-4'>


                <div className="row m-0 pt-3 mt-3">
                    <div className='col-12 mb-4 pb-3'>
                        <h1 className=' mt-1 architect-interior'>Architect & Interior Designers Preview Catalogue</h1>
                        <p className='exhibitor-subheading mt-3'>We are circulating a PDF Preview Catalogue of Products available at the event by the Exhibitors of Hide India 2024 to  invited architects and interior designers to the event.</p>
                        <p className='exhibitor-subheading mt-2 mt-lg-0'>You are required to please fill in the below form with your business information for the same for us to share your profile with potential clients to prefix meetings.</p>
                        <p className='exhibitor-subheading mt-2 mt-lg-0'>Please upload your best 5 products images with high-resolution of 300 dpi</p>
                    </div>
                    <div className='col-lg-3 col-md-4 mb-3'>
                        <label for="formGroupExampleInput" class="form-label mb-0">Company Name <span className='text-danger'>*</span> </label>
                        <input
                            type="text"
                            className="form-control"
                            id="companyName"
                            onChange={(e) => setCompanyName(e.target.value)}
                        />
                    </div>
                    <div className='col-lg-3 col-md-4 mb-3'>
                        <label for="formGroupExampleInput" class="form-label mb-0">Contact Person<span className='text-danger'>*</span> </label>
                        <input
                            type="text"
                            className="form-control"
                            id="contactPerson"
                            onChange={(e) => setContactPerson(e.target.value)}
                        />
                    </div>
                    <div className='col-lg-3 col-md-4 mb-3'>
                        <label for="formGroupExampleInput" class="form-label mb-0">Contact Person Mobile No.<span className='text-danger'>*</span> </label>
                        <input
                            type="text"
                            className="form-control"
                            id="contactPersonNo"
                            onChange={(e) => setContactPersonMobile(e.target.value)}
                        />
                    </div>
                    <div className='col-lg-3 col-md-4 mb-3'>
                        <label for="formGroupExampleInput" class="form-label mb-0">Alternate  Contact Number</label>
                        <input
                            type="text"
                            className="form-control"
                            id="contactNo"
                            onChange={(e) => setContactNo(e.target.value)}
                        />
                    </div>
                    <div className='col-lg-3 col-md-4 mb-3'>
                        <label for="formGroupExampleInput" class="form-label mb-0">Email Id<span className='text-danger'>*</span></label>
                        <input
                            type="email"
                            className="form-control"
                            id="emailid"
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>

                    {/* <div className='col-lg-3 col-md-4 mb-3'>
                        <label for="formGroupExampleInput" class="form-label mb-0">Area<span className='text-danger'>*</span> </label>
                        <input
                            type="text"
                            className="form-control"
                            id="area"
                            onChange={(e) => setArea(e.target.value)}
                            placeholder=''
                        />
                    </div> */}
                    <div className='col-lg-3 col-md-4 mb-3'>
                        <label for="formGroupExampleInput" class="form-label mb-0">Stall No.<span className='text-danger'>*</span> </label>
                        <input
                            type="text"
                            className="form-control"
                            id="stallNo"
                            onChange={(e) => setStallNo(e.target.value)}
                        />
                    </div>


                    <div className='col-lg-3 col-md-4 mb-3'>
                        <label for="formGroupExampleInput" class="form-label mb-0">Website</label>
                        <input
                            type="text"
                            className="form-control"
                            id="website"
                            onChange={(e) => setWebsite(e.target.value)}
                        />
                    </div>


                    {/* <div className='col-lg-3 col-md-4 mb-3'>
                        <label for="formGroupExampleInput" class="form-label mb-0">Industry <span className='text-danger'>*</span></label>
                        <select class="form-select" aria-label="Default select example" onChange={(e) => setIndustry(e.target.value)} >
                            <option selected disabled={industry != ""}>Select Industry</option>
                            {industryList.map((industryType) => {
                                return (
                                    <option key={industryType.fld_id} value={industryType.fld_name}>{industryType.fld_name}</option>
                                )
                            })}
                        </select>
                    </div> */}
                    <div className='col-lg-3 col-md-4 mb-3'>
                        <label for="formGroupExampleInput" class="form-label mb-0">Address <span className='text-danger'>*</span></label>
                        {/* <input
                            type="text"
                            className="form-control"
                            id="address"
                            onChange={(e) => setAddress(e.target.value)}
                        /> */}
                        <textarea class="form-control" placeholder="" id="floatingTextarea2" onChange={(e) => setAddress(e.target.value)} ></textarea>

                    </div>
                    <div className='col-lg-3 col-md-4 mb-3'>
                        <label for="formGroupExampleInput" class="form-label mb-0">Company And Product Profile</label>
                        {/* <input
                            type="text"
                            className="form-control"
                            id="companyDes"
                            onChange={(e) => setCompanyDescription(e.target.value)}
                        /> */}
                        <textarea class="form-control" placeholder="" id="floatingTextarea2" onChange={(e) => setCompanyDescription(e.target.value)} ></textarea>

                    </div>

                    <div className="col-12 mb-3">
                        <label for="formGroupExampleInput" class="form-label mb-0">Upload Product Images<span className='text-danger'>*</span></label>
                        <p className='upload-text'>upload maximum five images</p>
                        <div className='d-flex gap-4 '>
                            <Upload
                                customRequest={onaction}
                                listType="picture-card"
                                fileList={fileList}
                                onPreview={handlePreview}
                                onChange={handleChange}
                            >
                                {/* at most 8 images can be selected  */}
                                {fileList.length >= 5 ? null : uploadButton}
                            </Upload>
                            {previewImage && (
                                <Image
                                    wrapperStyle={{
                                        display: "none",
                                    }}
                                    preview={{
                                        visible: previewOpen,
                                        onVisibleChange: (visible) => setPreviewOpen(visible),
                                        afterOpenChange: (visible) => !visible && setPreviewImage(""),
                                    }}
                                    src={previewImage}
                                />
                            )}
                        </div>
                    </div>

                    <div className="col-12 mb-3">
                        <button
                            type="button"
                            className=" mt-3 btn btn-danger download"
                            onClick={submitExhibitorDetails}
                        >
                            Submit
                        </button>
                    </div>
                </div>

            </div>
            <Footer />
        </>
    )
}

export default ExhibitorDetails;