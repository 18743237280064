import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../Comp/category.css";

function Homecarousel() {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      ,
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="container-fluid mt-4 mt-lg-5">
        <div class="title px-lg-2">
          <h2 style={{ marginBottom: "35px" }}>Clients</h2>
        </div>
        <div className="container-md container-fluid mb-lg-5 mb-2">
          <Slider {...settings} className="homebottomlogo">
            {/* <div className="d-flex justify-content-center">
            <img src="/assets/images/companylogo/classic.png" alt="Classic Antiques" style={{height: '170px'}} className="img-responsive img-fluid client-logo-bottom" />
          </div>
          <div className="d-flex justify-content-center">
            <img src="/assets/images/companylogo/higlo.png" alt="Higold" style={{height: '170px'}}  className="img-responsive img-fluid client-logo-bottom" />
          </div>
          <div className="d-flex justify-content-center">
            <img src="/assets/images/companylogo/smart.png" alt="Smart Seating" style={{height: '170px'}}  className="img-responsive img-fluid client-logo-bottom" />
          </div>
          <div className="d-flex justify-content-center">
            <img src="/assets/images/companylogo/aura.png" alt="Aura" style={{height: '170px'}}  className="img-responsive img-fluid client-logo-bottom" />
          </div>
          <div className="d-flex justify-content-center">
            <img src="/assets/images/companylogo/creative.png" alt="Creative Seating System" style={{height: '170px'}}  className="img-responsive img-fluid client-logo-bottom" />
          </div>
          <div className="d-flex justify-content-center">
            <img src="/assets/images/companylogo/dream.png" alt='Dreamspan' style={{height: '170px'}}  className="img-responsive img-fluid client-logo-bottom" />
          </div>
          <div className="d-flex justify-content-center">
            <img src="/assets/images/companylogo/trezure.png" alt="Rezure" style={{height: '170px'}}  className="img-responsive img-fluid client-logo-bottom" />
          </div>
          <div className="d-flex justify-content-center">
            <img src="/assets/images/companylogo/beddies.png" alt="Beddies" style={{height: '170px'}}  className="img-responsive img-fluid client-logo-bottom" />
          </div>
          <div className="d-flex justify-content-center">
            <img src="/assets/images/companylogo/tabillo.png" alt="Tabillo" style={{height: '170px'}}  className="img-responsive img-fluid client-logo-bottom" />
          </div>
          <div className="d-flex justify-content-center">
            <img src="/assets/images/companylogo/hifza.png" alt="Hifza" style={{height: '170px'}}  className="img-responsive img-fluid client-logo-bottom" />
          </div>
          <div className="d-flex justify-content-center">
            <img src="/assets/images/companylogo/johnsons.png" alt="Johnsons" style={{height: '170px'}}  className="img-responsive img-fluid client-logo-bottom" />
          </div>
          <div className="d-flex justify-content-center">
            <img src="/assets/images/companylogo/jdschool.png" alt="Hifza" style={{height: '170px'}}  className="img-responsive img-fluid client-logo-bottom" />
          </div>
          <div className="d-flex justify-content-center">
            <img src="/assets/images/companylogo/veer.png" alt="Johnsons" style={{height: '170px'}}  className="img-responsive img-fluid client-logo-bottom" />
          </div>          

           new logo 15-9-2023 

          <div className="d-flex justify-content-center">
            <img src="/assets/images/companylogo/global.png" alt="Global" style={{height: '170px'}}  className="img-responsive img-fluid client-logo-bottom" />
          </div>

          <div className="d-flex justify-content-center">
            <img src="/assets/images/companylogo/rk.png" alt="R.K Enterprise" style={{height: '170px'}}  className="img-responsive img-fluid client-logo-bottom" />
          </div>

          <div className="d-flex justify-content-center">
            <img src="/assets/images/companylogo/eliteo.png" alt="Eliteo" style={{height: '170px'}}  className="img-responsive img-fluid client-logo-bottom" />
          </div>

          <div className="d-flex justify-content-center">
            <img src="/assets/images/companylogo/sai.png" alt="Sai" style={{height: '170px'}}  className="img-responsive img-fluid client-logo-bottom" />
          </div>

          <div className="d-flex justify-content-center">
            <img src="/assets/images/companylogo/sadguru.png" alt="Sadguru" style={{height: '170px'}}  className="img-responsive img-fluid client-logo-bottom" />
          </div>
          
          <div className="d-flex justify-content-center">
            <img src="/assets/images/companylogo/real.png" alt="Real Plast" style={{height: '170px'}}  className="img-responsive img-fluid client-logo-bottom" />
          </div>
          
          <div className="d-flex justify-content-center">
            <img src="/assets/images/companylogo/serviceg.png" alt="Global service and solution" style={{height: '170px'}}  className="img-responsive img-fluid client-logo-bottom" />
          </div>

          <div className="d-flex justify-content-center">
            <img src="/assets/images/companylogo/vox.png" alt="vox" style={{height: '170px'}}  className="img-responsive img-fluid client-logo-bottom" />
          </div>

          <div className="d-flex justify-content-center">
            <img src="/assets/images/companylogo/Mahana.png" alt="Mahana" style={{height: '170px'}}  className="img-responsive img-fluid client-logo-bottom" />
          </div>

          <div className="d-flex justify-content-center">
            <img src="/assets/images/companylogo/decorative.png" alt="decorative" style={{height: '170px'}}  className="img-responsive img-fluid client-logo-bottom" />
          </div> */}

            {/* *** New logo 19-4-2023 *****/}
            {/* <div className="d-flex justify-content-center">
            <img src="/assets/images/companylogo/credai.png" alt="Credai" style={{height: '170px'}} className="img-responsive img-fluid" />
          </div>
          <div className="d-flex justify-content-center">
            <img src="/assets/images/companylogo/gar.png" alt="GAR" style={{height: '170px'}} className="img-responsive img-fluid" />
          </div>
          <div className="d-flex justify-content-center">
            <img src="/assets/images/companylogo/ghralogo.png" alt="GHRA" style={{height: '170px'}} className="img-responsive img-fluid" />
          </div>
          <div className="d-flex justify-content-center">
            <img src="/assets/images/companylogo/indianinterior.png" alt="Indian Interior" style={{height: '170px'}} className="img-responsive img-fluid" />
          </div>
          <div className="d-flex justify-content-center">
            <img src="/assets/images/companylogo/iiid.jpeg" alt="IIID" style={{height: '170px'}} className="img-responsive img-fluid" />
          </div> */}

            {/* <div className="d-flex justify-content-center">
            <img
              src="/assets/images/revisedhideindia2023logo/1.png"
              alt="IIID"
              style={{ height: "170px" }}
              className="img-responsive img-fluid"
            />
          </div> */}

            {/*New 2024 */}
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/new-2024-logo/AK Apple Logo.jpg"
                alt="AK Apple Logo"
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/new-2024-logo/Zerob Hydrolife.jpg"
                alt="Zerob Hydrolife Logo"
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/new-2024-logo/Credence furniture.jpg"
                alt="Credence furniture Logo"
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/new-2024-logo/EXPL-Logo.png"
                alt="EXPL Logo"
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/new-2024-logo/Flashlab Logo.png"
                alt="Flashlab Logo"
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/new-2024-logo/Supreme-furniture-Logo.jpg"
                alt="Supreme furniture Logo"
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/new-2024-logo/the draping studio.jpg"
                alt="The draping studio"
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/new-2024-logo/shangarh.jpeg"
                alt="shangarh Logo"
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/new-2024-logo/natural stone.jpeg"
                alt="natural stone Logo"
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/new-2024-logo/metal trends.jpeg"
                alt="Metal trends Logo"
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/new-2024-logo/destiny plast.jpeg"
                alt="Destiny plast Logo"
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/new-2024-logo/twinkle group.jpeg"
                alt="Twinkle group Logo"
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            {/* 2024 */}
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/2024-logo/33.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/2024-logo/34.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/2024-logo/35.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/2024-logo/36.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/2024-logo/37.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/2024-logo/1.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/2024-logo/2.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/2024-logo/3.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/2024-logo/4.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/2024-logo/5.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/2024-logo/6.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/2024-logo/7.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/2024-logo/8.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/2024-logo/9.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/2024-logo/10.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/2024-logo/11.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/2024-logo/12.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/2024-logo/13.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/2024-logo/14.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/2024-logo/15.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/2024-logo/16.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/2024-logo/17.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/2024-logo/18.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/2024-logo/19.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/2024-logo/20.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/2024-logo/21.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/2024-logo/22.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/2024-logo/23.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/2024-logo/24.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/2024-logo/25.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/2024-logo/26.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/2024-logo/27.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/2024-logo/28.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/2024-logo/29.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/2024-logo/30.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/2024-logo/31.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/2024-logo/32.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>

            {/* 2024 end */}

            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/revisedhideindia2023logo/Astral.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/revisedhideindia2023logo/D.jpg"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/revisedhideindia2023logo/decowood.jpg"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/revisedhideindia2023logo/devaaj.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/revisedhideindia2023logo/E-cool.jpg"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/revisedhideindia2023logo/Greenlam.jpg"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/revisedhideindia2023logo/maruti.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/revisedhideindia2023logo/plants.jpg"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/revisedhideindia2023logo/reynarch.jpg"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/revisedhideindia2023logo/sanghar.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/revisedhideindia2023logo/SU.jpg"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/revisedhideindia2023logo/toto.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/revisedhideindia2023logo/vaillant.svg"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>

            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/revisedhideindia2023logo/D.jpg"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/revisedhideindia2023logo/2022/decorative-luxury.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/revisedhideindia2023logo/2022/dreamspan.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/revisedhideindia2023logo/2022/h-r-carpets.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/revisedhideindia2023logo/2022/r-k-enterprise.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/revisedhideindia2023logo/2023/aquatic-solutions.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/revisedhideindia2023logo/2023/cane-centre.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/revisedhideindia2023logo/2023/cedar.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/revisedhideindia2023logo/2023/mitsubishi-electric.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/revisedhideindia2023logo/1.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/revisedhideindia2023logo/2.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/revisedhideindia2023logo/3.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/revisedhideindia2023logo/4.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/revisedhideindia2023logo/5.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/revisedhideindia2023logo/6.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/revisedhideindia2023logo/7.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/revisedhideindia2023logo/8.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/revisedhideindia2023logo/9.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>

            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/revisedhideindia2023logo/11.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/revisedhideindia2023logo/12.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/revisedhideindia2023logo/13.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/revisedhideindia2023logo/14.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/revisedhideindia2023logo/15.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/revisedhideindia2023logo/16.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/revisedhideindia2023logo/17.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/revisedhideindia2023logo/18.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/revisedhideindia2023logo/19.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/revisedhideindia2023logo/20.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/revisedhideindia2023logo/21.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/revisedhideindia2023logo/22.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/revisedhideindia2023logo/23.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/revisedhideindia2023logo/24.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/revisedhideindia2023logo/25.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>

            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/revisedhideindia2023logo/27.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/revisedhideindia2023logo/28.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/revisedhideindia2023logo/29.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/revisedhideindia2023logo/G-H&R-A-LOGO.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/revisedhideindia2023logo/IID-Logo-Goa.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/revisedhideindia2023logo/IIHM-LOGO.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/revisedhideindia2023logo/IIID-2nd-logo.jpeg"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/revisedhideindia2023logo/JD-School-logo.jpg"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/revisedhideindia2023logo/Navelcar-Logo-1.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/revisedhideindia2023logo/Navelcar-Logo-2.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/revisedhideindia2023logo/Navelcar-Logo-3.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/revisedhideindia2023logo/Navelcar-Logo-4.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/revisedhideindia2023logo/Navelcar-Logo-5.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/revisedhideindia2023logo/Navelcar-Logo-6.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/revisedhideindia2023logo/Navelcar-Logo-7.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/revisedhideindia2023logo/Navelcar-Logo-8.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/revisedhideindia2023logo/Navelcar-Logo-9.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/revisedhideindia2023logo/Navelcar-Logo-10.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/revisedhideindia2023logo/Navelcar-Logo-11.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/revisedhideindia2023logo/Navelcar-Logo-12.png"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>

            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/companylogo/toto.jpeg"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            {/* <div className="d-flex justify-content-center">
              <img
                src="/assets/images/companylogo/astral-bathware.jpeg"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div> */}
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/companylogo/3m.jpeg"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/companylogo/kohler.jpeg"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/companylogo/zankar.jpeg"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/companylogo/cotto.jpeg"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/companylogo/astral-pipes.jpeg"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/companylogo/sait-gobain.jpeg"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
            <div className="d-flex justify-content-center">
              <img
                src="/assets/images/companylogo/sanghar.jpeg"
                alt=""
                style={{ height: "170px" }}
                className="img-responsive img-fluid client-logo-bottom"
              />
            </div>
          </Slider>
          <div class="title mt-5 pt-4 px-lg-2">
            <h2 style={{ marginBottom: "35px" }}>Event Highlights</h2>
          </div>
          <div className="row">
            <div className="col-12 youtube-video">
              <iframe
                width="100%"
                src="https://www.youtube.com/embed/Y6JdxYmfMk8?autoplay=1&loop=1&playlist=Y6JdxYmfMk8&mute=1"
                title="Hospitality Interior and Design Expo Goa 2024, An Introductions Event"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"></iframe>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Homecarousel;
