
import React, { Component } from 'react';
import PostApiCall from "../../API/POSTAPI";
import GetApiCall from '../../API/GETAPI';
import Notiflix from 'notiflix';

export default class ExhibitorRegistration extends Component {

    constructor() {
        super()

        this.state = {
            EmailRegex: /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,

            NumRegex: /^[0-9]*$/,

            exhid: null,
            type: null,
            boothType: null,
            bootharea: "",
            products: [],
            otherproduct: "",

            contactfirstname: "",
            contactlastname: "",
            contactdesignation: "",
            Phonecode: "",
            contactmobile: "",
            contactmail: "",
            contactaddressline1: "",
            contactaddressline2: "",
            contactpincode: "",
            contactcity: null,
            contactState: null,
            contactCountry: null,
            company: "",
            contactwebsite: "",





            BrandArray: [{ brand: "", product: "", country: "" }],
            userid: null,
            emailid: null,


            participateType: null,

            brands: "",
            agentName: null,
            agentmail: null,


            openingType: null,

            title: null,
            firstname: null,
            lastname: null,
            designation: null,
            mobile: "",
            organisationmail: null,


            contacttitle: null,

            contactwhatsapp: "",

            contacttelSTDcode: "",
            contacttelnumber: "",
            contactfaxSTDcode: "",
            contactfaxnumber: "",

            contactPersonParentGroup: null,



            CountryData: [],
            CountryId: null,

            StateData: [],
            StateId: null,

            CityData: [],
            CityId: null,


        }
    }


    componentDidMount() {

        GetApiCall.getRequest("Get_Country").then((resultdes) =>
            resultdes.json().then((obj) => {

                if (obj.data.length != 0) {
                    // console.log(obj.data)
                    this.setState({ CountryData: obj.data })
                }
            })
        )


    }

    onCountryChange() {


        PostApiCall.postRequest(
            {
                countryid: this.state.CountryId,
            },
            "Get_State"
        ).then((results) =>
            results.json().then((objstate) => {
                if (results.status == 200 || results.status == 201) {
                    if (objstate.data.length != 0) {

                        this.setState({ StateData: objstate.data });
                    }
                }
            })
        );
    }

    onStateChange() {



        PostApiCall.postRequest(
            {
                stateid: this.state.StateId,
            },
            "Get_City"
        ).then((resultscity) =>
            resultscity.json().then((objcity) => {
                if (resultscity.status == 200 || resultscity.status == 201) {
                    if (objcity.data.length != 0) {
                        this.setState({
                            CityId: objcity.data[0].value,
                            CityData: objcity.data,
                            City: objcity.data[0].label,
                        });
                    }
                }
            })
        );

    }



    feedproducts(e) {

        if (e.target.checked) {
            this.setState({ products: [...this.state.products, e.target.value] })
        } else {
            this.setState({ products: this.state.products.filter(val => val != e.target.value) })
        }
    }


    validOrgHead() {


        if (this.state.boothType != null) {
            if (this.state.products.length != 0 || this.state.otherproduct != "") {

                if (this.state.contactfirstname != "") {
                    if (this.state.contactlastname != "") {
                        if (this.state.contactdesignation != "") {
                            if (this.state.contactmobile != "") {
                                if (this.state.contactmobile.length == 10) {
                                    if (this.state.contactmail != "") {
                                        if (this.state.EmailRegex.test(this.state.contactmail)) {
                                            if (this.state.contactaddressline1 != "") {
                                                if (this.state.contactCountry != null) {
                                                    if (this.state.contactState != null) {
                                                        if (this.state.contactcity != null) {
                                                            if (this.state.contactpincode != "") {
                                                                if (this.state.contactpincode.length == 6) {
                                                                    this.saveExhibitor()
                                                                } else {
                                                                    Notiflix.Notify.failure("Please Enter Valid Pincode ")
                                                                }
                                                            } else {
                                                                Notiflix.Notify.failure("Please Enter Pincode ")
                                                            }
                                                        } else {
                                                            Notiflix.Notify.failure("Please Enter City ")
                                                        }
                                                    } else {
                                                        Notiflix.Notify.failure("Please Enter State ")
                                                    }
                                                } else {
                                                    Notiflix.Notify.failure("Please Enter Country ")
                                                }
                                            } else {
                                                Notiflix.Notify.failure("Please Enter Address 1 ")
                                            }
                                        } else {
                                            Notiflix.Notify.failure("Please Enter Valid Email ")
                                        }
                                    } else {
                                        Notiflix.Notify.failure("Please Enter Email ")
                                    }
                                } else {
                                    Notiflix.Notify.failure("Please Enter Valid Mobile ")
                                }
                            } else {
                                Notiflix.Notify.failure("Please Enter Mobile ")
                            }
                        } else {
                            Notiflix.Notify.failure("Please Enter Designation ")
                        }
                    } else {
                        Notiflix.Notify.failure("Please Enter Last Name ")
                    }
                } else {
                    Notiflix.Notify.failure("Please Enter First Name ")
                }

            } else {
                Notiflix.Notify.failure("Please Select any Products ")
            }
        } else {
            Notiflix.Notify.failure("Please Choose Type of Booth ")
        }






    }



    saveExhibitor() {


        PostApiCall.postRequest(
            {
                exhid: this.state.exhid,
                clientfrom: this.state.type,
                participation: null,
                agentname: null,
                agentemail: null,
                companyname: this.state.company,
                approxarea: this.state.bootharea,
                boothType: this.state.boothType,
                brands: this.state.products.length != 0 ? this.state.products.toString() : this.state.otherproduct,
                orgheadtitle: null,
                orgheadfirstname: null,
                orgheadlastname: null,
                designation: null,
                mobile: null,
                emailid: null,

                countrycode : this.state.Phonecode,

                contactpersontitle: this.state.contacttitle,
                contactpersonfirstname: this.state.contactfirstname,
                contactpersonlastname: this.state.contactlastname,
                contactpersondesignation: this.state.contactdesignation,
                contactpersonmobnumber: this.state.contactmobile,
                contactpersonwhatsapp: this.state.contactwhatsapp,
                contactpersonemailid: this.state.contactmail,
                contactpersoncountry: this.state.contactCountry,
                contactpersonaddress1: this.state.contactaddressline1,
                contactpersonaddress2: this.state.contactaddressline2,
                contactpersonaddress3: this.state.contactaddressline3,
                contactpersonpincode: this.state.contactpincode,
                contactpersoncity: this.state.contactcity,
                contactpersonstate: this.state.contactState,
                contactpersontelno: this.state.contacttelnumber,
                contactpersonfaxno: this.state.contactfaxnumber,
                contactpersonwebsite: this.state.contactwebsite,
                contactpersonparentgroup: this.state.contactPersonParentGroup,
            },
            "Update_Exhibitor_ifex"
        ).then((results) =>
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {

                    PostApiCall.postRequest(
                        {

                            email: this.state.contactmail,
                            name: `${this.state.contactfirstname} ${this.state.contactlastname}`
                        },
                        "Send_Ticket_Exhibitor_Mailer_ifex"
                    ).then((results) =>
                        results.json().then((obj) => {
                            if (results.status == 200 || results.status == 201) {

                                PostApiCall.postRequest(
                                    {
                                        Participation: this.state.participateType == null ? "Empty Field" : this.state.participateType,
                                        BoothType: this.state.boothType == null ? "Empty Field" : this.state.boothType,
                                        BoothArea: this.state.bootharea == "" ? "Empty Field" : this.state.bootharea,
                                        Products: this.state.products.length != 0 ? this.state.products.toString() : this.state.otherproduct,
                                        FullName: `${this.state.contactfirstname} ${this.state.contactlastname}`,
                                        Designation: this.state.contactdesignation,
                                        Mobile: this.state.contactmobile,
                                        Email: this.state.contactmail,
                                        Address: `${this.state.contactaddressline1} ${this.state.contactaddressline2}`,
                                        Pincode: this.state.contactpincode,
                                        City: this.state.contactcity == null ? "Empty Field" : this.state.contactcity,
                                        State: this.state.contactState == null ? "Empty Field" : this.state.contactState,
                                        Country: this.state.contactCountry == null ? "Empty Field" : this.state.contactCountry,
                                        CompanyName: this.state.company == "" ? "Empty Field" : this.state.company,
                                        CompanyWebsite: this.state.contactwebsite == "" ? "Empty Field" : this.state.contactwebsite,
                                    },
                                    "Send_Exhibitor_Form_Mailer_ifex"
                                ).then((results) =>
                                    results.json().then((obj) => {
                                        if (results.status == 200 || results.status == 201) {
                                            Notiflix.Loading.remove();
                                            Notiflix.Notify.success("Thank you, Please Check Your Mail.");
                                            // window.location.href = '/'
                                            window.location.reload();
                                        } else {
                                            Notiflix.Loading.remove();
                                            Notiflix.Notify.failure(obj.data);
                                        }
                                    })
                                );



                                Notiflix.Loading.remove();
                                Notiflix.Notify.success("Thank you, Please Check Your Mail.");
                                // window.location.href = '/'
                            } else {
                                Notiflix.Loading.remove();
                                Notiflix.Notify.failure(obj.data);
                            }
                        })
                    );

                } else {
                    Notiflix.Loading.remove();
                    Notiflix.Notify.failure(obj.data);
                }
            })
        );

    }


    render() {


        return (
            <>

                <div className='container-fluid ps-lg-3'>

                    <div class="title"> <h1 className='mt-5 ms-2'>Book Your Stall</h1></div>

                    <p className='mt-4 ms-2 '>We are interested in exhibiting at HIDE India 2024. Information about our organisation is given below.</p>

                    <div class="form-check form-check-inline ms-2">
                        <input class="form-check-input mt-2" type="radio" name="inlinetypeRadio" onChange={e => this.setState({ type: e.target.value })} id="domesticradio" value="Domestic" />
                        <label class="form-check-label" for="domesticradio">Domestic</label>
                    </div>

                    <div class="form-check form-check-inline ms-2 ">
                        <input class="form-check-input mt-2" type="radio" onChange={e => this.setState({ type: e.target.value })} name="inlinetypeRadio" id="internationalradio" value="International" />
                        <label class="form-check-label" value="International" for="internationalradio">International</label>
                    </div>




                    <div className='row m-0 pt-3 justify-content-between'>

                        <div className='mt-3 col-md-6'>
                            <p className='fw-bold'>Please Tick : Type of booth required <span className='text-danger'>*</span></p>

                            <div class="form-check form-check-inline">
                                <input class="form-check-input mt-2" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="Build-up Booth (Min. 9 sq. mtrs.)" onChange={e => this.setState({ boothType: e.target.value })} />
                                <label class="form-check-label" for="inlineRadio1">Build-up Booth (Min. 9 sq. mtrs.) <b style={{ fontSize: "12px" }}>(Ready booth)</b></label>
                            </div>

                            <div class="form-check form-check-inline ">
                                <input class="form-check-input mt-2" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="Raw Space (Min. 18 sq. mtrs.)" onChange={e => this.setState({ boothType: e.target.value })} />
                                <label class="form-check-label" for="inlineRadio2">Raw Space (Min. 18 sq. mtrs.)</label>
                            </div>
                        </div>

                    </div>


                    <div className='row  m-0'>
                        <div class="my-3 col-md-4 ">
                            <label for="formGroupExampleInput" class="form-label">Approximate Area Required (Sq. m)</label><br />
                            <input type="text" class="form-control w-50 d-inline" id="formGroupExampleInput" value={this.state.bootharea} onChange={e => {
                                if (this.state.NumRegex.test(e.target.value)) {
                                    this.setState({ bootharea: e.target.value })
                                }
                            }} />

                        </div>

                    </div>



                    <div className='row m-0 mt-5'>

                        <p className='fw-bold ' >Product/s <span className='text-danger'>*</span></p>

                        <div className=' row m-0 justify-content-between'>

                            <div class="form-check col-md-2" style={{ fontSize: "12px" }}>
                                <input class="form-check-input mt-2" type="checkbox" value="Furniture" id="product-Furniture" onChange={this.feedproducts.bind(this)} />
                                <label class="form-check-label" for="product-Furniture">Furniture</label>
                            </div>

                            <div class="form-check col-md-2" style={{ fontSize: "12px" }}>
                                <input class="form-check-input mt-2" type="checkbox" value="Furnishings" id="product-Furnishings" onChange={this.feedproducts.bind(this)} />
                                <label class="form-check-label" for="product-Furnishings">Furnishings</label>
                            </div>

                            <div class="form-check col-md-2" style={{ fontSize: "12px" }}>
                                <input class="form-check-input mt-2" type="checkbox" value="Doors & Windows" id="product-Doors" onChange={this.feedproducts.bind(this)} />
                                <label class="form-check-label" for="product-Doors">Doors & Windows</label>
                            </div>

                            <div class="form-check col-md-2" style={{ fontSize: "12px" }}>
                                <input class="form-check-input mt-2" type="checkbox" value="Signage" id="product-Signage" onChange={this.feedproducts.bind(this)} />
                                <label class="form-check-label" for="product-Signage">Signage</label>
                            </div>

                            <div class="form-check col-md-2" style={{ fontSize: "12px" }}>
                                <input class="form-check-input mt-2" type="checkbox" value="Lighting" id="product-Lighting" onChange={this.feedproducts.bind(this)} />
                                <label class="form-check-label" for="product-Lighting">Lighting</label>
                            </div>

                            <div class="form-check col-md-2" style={{ fontSize: "12px" }}>
                                <input class="form-check-input mt-2" type="checkbox" value="Interiors" id="product-Interiors" onChange={this.feedproducts.bind(this)} />
                                <label class="form-check-label" for="product-Interiors">Interiors</label>
                            </div>

                            <div class="form-check col-md-2" style={{ fontSize: "12px" }}>
                                <input class="form-check-input mt-2" type="checkbox" value="Ceilings" id="product-Ceilings" onChange={this.feedproducts.bind(this)} />
                                <label class="form-check-label" for="product-Ceilings">Ceilings</label>
                            </div>

                            <div class="form-check col-md-2" style={{ fontSize: "12px" }}>
                                <input class="form-check-input mt-2" type="checkbox" value="Bathrooms" id="product-Bathrooms" onChange={this.feedproducts.bind(this)} />
                                <label class="form-check-label" for="product-Bathrooms">Bathrooms</label>
                            </div>

                            <div class="form-check col-md-2" style={{ fontSize: "12px" }}>
                                <input class="form-check-input mt-2" type="checkbox" value="Flooring" id="product-Flooring" onChange={this.feedproducts.bind(this)} />
                                <label class="form-check-label" for="product-Flooring">Flooring</label>
                            </div>

                            <div class="form-check col-md-2" style={{ fontSize: "12px" }}>
                                <input class="form-check-input mt-2" type="checkbox" value="Art" id="product-Art" onChange={this.feedproducts.bind(this)} />
                                <label class="form-check-label" for="product-Art">Art</label>
                            </div>

                            <div class="form-check col-md-2" style={{ fontSize: "12px" }}>
                                <input class="form-check-input mt-2" type="checkbox" value="Kitchens" id="retail-Kitchens" onChange={this.feedproducts.bind(this)} />
                                <label class="form-check-label" for="retail-Kitchens">Kitchens</label>
                            </div>

                            <div class="form-check col-md-2" style={{ fontSize: "12px" }}>
                                <input class="form-check-input mt-2" type="checkbox" value="Smart Security Systems" id="retail-Smart" onChange={this.feedproducts.bind(this)} />
                                <label class="form-check-label" for="retail-Smart">Smart Security Systems</label>
                            </div>

                            <div class="form-check col-md-2" style={{ fontSize: "12px" }}>
                                <input class="form-check-input mt-2" type="checkbox" value="Bar Design" id="retail-Design" onChange={this.feedproducts.bind(this)} />
                                <label class="form-check-label" for="retail-Design">Bar Design</label>
                            </div>

                            <div class="form-check col-md-auto me-auto" style={{ fontSize: "12px" }}>
                                <input class="form-check-input mt-2" type="checkbox" value="Surface Materials & other products related to the industry" id="retail-Surface" onChange={this.feedproducts.bind(this)} />
                                <label class="form-check-label" for="retail-Surface">Surface Materials & other products related to the industry</label>
                            </div>

                        </div>


                        <div className='col-12 mt-3'>
                            <div class=" col-md-4 mb-3">
                                <label for="formGroupExampleInput" class="form-label mb-0">Others </label>
                                <input type="text" class="form-control" id="formGroupExampleInput" placeholder='Please Specify...' value={this.state.firstname} onChange={(text) => {
                                    this.setState({ otherproduct: text.target.value })
                                }} />
                            </div>
                        </div>





                    </div>




                    <div className=' row m-0 mt-5'>
                        <p className='fw-bold' >Contact Person</p>



                        <div class=" col-md-4 mb-3 ">
                            <label for="formGroupExampleInput" class="form-label mb-0">First Name <span className='text-danger'>*</span></label>
                            <input type="text" class="form-control" id="formGroupExampleInput" value={this.state.contactfirstname} onChange={(text) => {
                                this.setState({
                                    contactfirstname: text.target.value,
                                });
                            }} />
                        </div>

                        <div class=" col-md-4 mb-3 ">
                            <label for="formGroupExampleInput" class="form-label mb-0">Last Name <span className='text-danger'>*</span> </label>
                            <input type="text" class="form-control" id="formGroupExampleInput" value={this.state.contactlastname} onChange={(text) => {
                                this.setState({
                                    contactlastname: text.target.value,
                                });
                            }} />
                        </div>

                        <div class="col-md-4 mb-3 ">
                            <label for="inputState" class="form-label mb-0">Designation <span className='text-danger'>*</span></label>
                            <select id="inputState" class="form-select" onChange={e => this.setState({ contactdesignation: e.target.value })}>
                                <option value="">Choose...</option>
                                <option value="CEO" >CEO</option>
                                <option value="COO" >COO</option>
                                <option value="CFO" >CFO</option>
                                <option value="CMO" >CMO</option>
                                <option value="CFO" >CTO</option>
                                <option value="President" >President</option>
                                <option value="Manager">Manager</option>
                                <option value="Marketing Manager" >Marketing Manager</option>
                                <option value="Sr. Manager" >Sr. Manager</option>
                                <option value="Deputy Manager" >Deputy Manager</option>
                                <option value="Assistant Manager" >Assistant Manager</option>
                                <option value="Assistant General Manager" >Assistant General Manager</option>
                                <option value="General Manager" >General Manager</option>
                                <option value="Chairman" >Chairman</option>
                                <option value="Sales Executive" >Sales Executive</option>
                                <option value="Team Leader" >Team Leader</option>
                                <option value="Vice President" >Vice President</option>
                                <option value="Administrative Officer" >Administrative Officer</option>
                                <option value="Accountant" >Accountant</option>
                                <option value="Managing Director" >Managing Director</option>
                                <option value="Director" >Director</option>
                                <option value="Proprietor" >Proprietor</option>
                                <option value="Individual" >Individual</option>

                            </select>
                        </div>


                        <div class=" col-md-6 mb-3">

                            <div className='row m-0'>
                                <div className='col-4 ps-0'>
                                    <label for="formGroupExampleInput" class="form-label mb-0">Country Code <span className='text-danger'>*</span> </label>
                                    <select id="inputState" class="form-select" onChange={e => this.setState({ Phonecode: e.target.value })}>
                                        <option value="">Choose...</option>
                                        {this.state.CountryData.map(val => <option value={val.Phone_Code} >{val.Phone_Code} - {val.label}</option>)}
                                    </select>
                                </div>

                                <div className='col-8 pe-0'>
                                    <label for="formGroupExampleInput" class="form-label mb-0">Mobile No. <span className='text-danger'>*</span></label>
                                    <input type="text" class="form-control" value={this.state.contactmobile} id="formGroupExampleInput" onChange={mobile => {
                                        if (this.state.NumRegex.test(mobile.target.value)) {
                                            this.setState({ contactmobile: mobile.target.value })
                                        }
                                    }} />
                                </div>
                            </div>

                        </div>



                        <div class=" col-md-6 mb-3 ">
                            <label for="formGroupExampleInput" class="form-label mb-0">Email ID <span className='text-danger'>*</span> </label>
                            <input type="text" class="form-control" id="formGroupExampleInput" value={this.state.contactmail} onChange={(text) => {
                                this.setState({ contactmail: text.target.value });
                            }} />
                        </div>

                        {/* <div class="col-md-4 d-none d-md-flex mb-3"></div> */}


                        <div class=" col-md-4 mb-3 ">
                            <label for="formGroupExampleInput" class="form-label mb-0">Address Line 1 <span className='text-danger'>*</span></label>
                            <input type="text" class="form-control" id="formGroupExampleInput" value={this.state.contactaddressline1} onChange={(text) => {
                                this.setState({
                                    contactaddressline1: text.target.value,
                                });
                            }} placeholder='SHOP NO./ FLAT NO./ HOUSE NO.' />
                        </div>

                        <div class=" col-md-4 mb-3 ">
                            <label for="formGroupExampleInput" class="form-label mb-0">Address Line 2 </label>
                            <input type="text" class="form-control" id="formGroupExampleInput" value={this.state.contactaddressline2} onChange={(text) => {
                                this.setState({
                                    contactaddressline2: text.target.value,
                                });
                            }} placeholder='STREET/AREA/LOCALITY' />
                        </div>


                        <div class=" col-md-4 mb-3 ">
                            <label for="formGroupExampleInput" class="form-label mb-0">Country<span className='text-danger'>*</span> </label>
                            <select id="inputState" class="form-select" onChange={e => this.setState({ contactCountry: e.target.value.split(",")[1], CountryId: e.target.value.split(",")[0], contactState: "", StateId: null, StateData: [], contactCity: null, CityData: [], CityId: null, }, () => this.onCountryChange())}>
                                <option>Choose...</option>
                                {this.state.CountryData.map(val => <option value={`${val.value},${val.label}`} >{val.label}</option>)}
                            </select>
                        </div>

                        <div class=" col-md-4 mb-3 ">
                            <label for="formGroupExampleInput" class="form-label mb-0">State<span className='text-danger'>*</span> </label>
                            <select id="inputState" class="form-select" onChange={e => this.setState({ contactState: e.target.value.split(",")[1], StateId: e.target.value.split(",")[0], CityData: [], CityId: null, contactCity: null }, () => this.onStateChange())}>
                                <option>Choose...</option>
                                {this.state.StateData.map(val => <option value={`${val.value},${val.label}`} >{val.label}</option>)}
                            </select>
                        </div>

                        <div class=" col-md-4 mb-3 ">
                            <label for="formGroupExampleInput" class="form-label mb-0">City<span className='text-danger'>*</span> </label>
                            <select id="inputState" class="form-select" onChange={e => this.setState({ contactcity: e.target.value.split(",")[1], CityId: e.target.value.split(",")[0] })}>
                                <option>Choose...</option>
                                {this.state.CityData.map(val => <option value={`${val.value},${val.label}`} >{val.label}</option>)}
                            </select>
                        </div>

                        <div class=" col-md-4 mb-3 ">
                            <label for="formGroupExampleInput" class="form-label mb-0">Pin Code <span className='text-danger'>*</span></label>
                            <input type="text" class="form-control" value={this.state.contactpincode} onChange={(e) => {
                                if (this.state.NumRegex.test(e.target.value)) {
                                    this.setState({ contactpincode: e.target.value })
                                }
                            }} id="formGroupExampleInput" />
                        </div>


                        <div class=" col-md-4 mb-3 ">
                            <label for="formGroupExampleInput" class="form-label mb-0">Company Name </label>
                            <input type="text" class="form-control" id="formGroupExampleInput" value={this.state.company} onChange={(text) => {
                                this.setState({ company: text.target.value, });
                            }} />
                        </div>


                        <div class=" col-md-4 mb-3 ">
                            <label for="formGroupExampleInput" class="form-label mb-0"> Company Website </label>
                            <input type="text" class="form-control" id="formGroupExampleInput" value={this.state.contactwebsite} onChange={(text) => { this.setState({ contactwebsite: text.target.value, }); }} />
                        </div>
                    </div>

                    <div className='my-5'>
                        <h6 style={{ fontSize: "14px" }}>Please accept our request and allowed space accordingly</h6>

                        <h4 className='text-danger mt-2' style={{ fontWeight: "400", fontSize: "18px" }}>Important Note:</h4>

                        <p><span className='text-danger'>*</span> These fields are compulsory to fill.</p>

                        <p style={{ lineHeight: "18px" }}> Please note that this is only an Exhibitor Request Form and not Exhibitor Contract Form. Our team will contact you immediately with further details once you fill in this request form. You become an Exhibitor only after your Exhibitor Contract Form, along with payment is accepted by us.
                        </p>

                        <button className=' mt-3 btn btn-danger download' onClick={this.validOrgHead.bind(this)} >Submit</button>
                    </div>

                </div>
            </>
        )
    }
}
