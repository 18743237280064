
import React from 'react'
import { Helmet } from 'react-helmet'
import Category from '../Comp/Category'
import Footer from '../Comp/Footer'
import Header from '../Comp/Header'

const BookYourStall = () => {
    return (
        <>
            <Helmet>
                <title>Book Your Stall | Hide India | Hospitality Interior & Design Expo 2024</title>
                <meta name="description" content="" />
            </Helmet>

            <Header />

            <section class="slider">
                <div class="container-fluid p-0">
                    <img src='/assets/images/inner-banner/bookyourstall.webp' alt='' class="bannerimg2 forwebsite" />
                    <img src='/assets/images/banner/mobilebanner/bookyourstall.webp' alt='' class="bannerimg2 formobile" />
                    <div class="content">
                        <div class="bannertext">

                        </div>

                    </div>

                </div>
            </section>






            <section class="aboutusection">
                <div class="container-fluid">
                    <div class="title">
                        <h1 style={{ marginBottom: "10px" }} >Book your Stall</h1>

                        <p class="overviewtext">Join Hide India 2024 and Showcase your Excellent Designing Skills</p>

                    </div>
                    <div class="row">

                        <div class="col-md-7">
                            <iframe src="https://globaltrendz.info/mailer/10/enquiry" title="book your stall" height="800"
                                width="700" style={{ marginTop: "2em" }} class="forwebsite"></iframe>
                            <iframe src="https://globaltrendz.info/mailer/10/enquiry" title="book your stall" height="800"
                                width="320" style={{ marginTop: "2em" }} class="formobile"></iframe>
                        </div>


                        <div class="col-md-5">
                            <img src="./assets/images/exhibition.jpg" alt="About us" class="img-fluid aboutusimg pe-xl-2" />

                        </div>
                        {/* <!-- <div class="col-md-4">
                            <img src="./assets/images/bookstall.png" />

                        </div> --> */}

                    </div>
                </div>


            </section>


            <Footer />
        </>
    )
}

export default BookYourStall