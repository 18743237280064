
import React, { useRef } from 'react'
// import { Link } from 'react-router-dom';
import logo from '../assets/banner/hidelogo.png';
import pdf1 from '../pdf/HIDE INDIA 27-28-29 SEPTEMBER 2024  l  DR. SHYAMA PRASAD MUKHERJEE , PANJIM, GOA 1.pdf';
import pdfStall from '../pdf/HIDE INDIA 27-28-29 SEPTEMBER 2024  l  DR. SHYAMA PRASAD MUKHERJEE , PANJIM, GOA FINAL.pdf';
import { FiMail } from "react-icons/fi";
import { FaPhoneAlt } from "react-icons/fa";
import { Link } from 'react-router-dom';
import pdf from "../pdf/HIDEINDIA POST EVENT REPORT.pdf"

// import { HashLink } from 'react-router-hash-link';


const Header = () => {
    const handleLinkClick = (position) => {
        const targetDiv = document.getElementById(position);
        if (targetDiv) {
            const rect = targetDiv.getBoundingClientRect();
            const targetScrollTop = rect.top + window.pageYOffset - 100;
            window.scrollTo({
                top: targetScrollTop,
                behavior: "smooth",
            });
        }
    };

    const first = useRef();

    document.addEventListener('scroll', () => {

        if (window.scrollY > 10) {
            document.querySelector('#header').classList.add("fixed-header");
            document.querySelector(".headerlogo").style.width = "45%"
        } else {
            document.querySelector('#header').classList.remove("fixed-header");
            document.querySelector(".headerlogo").style.width = "80%"
        }
    })

    return (

        <>
            <header class="sticky-top">
                <div class="container-fluid">

                    <div class="row px-2 justify-content-between d-none d-lg-flex" style={{ backgroundColor: '#F7904C' }}>

                        <div className='col-auto d-flex align-items-center'>
                            <ul className='d-flex'>
                                <li>
                                    <a href='https://www.facebook.com/hideindiaexpo/' target="_blank">
                                        <img src='/assets/images/fbnw.svg' style={{ marginRight: '15px', paddingBottom: '5px', paddingTop: '5px' }} />
                                    </a>
                                </li>

                                <li>
                                    <a href='https://www.instagram.com/hideindiaexpo/' target='_blank'>
                                        <img src='/assets/images/instanw.svg' style={{ paddingBottom: '5px', paddingTop: '5px' }} />
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div className='col-auto  d-flex align-items-center justify-content-end numbers'>
                            <ul className='d-flex numbers'>
                                {/* <li style={{ marginRight: '5px' }}>
                                    <a href='tel: +91 78800 38782'>
                                        <p>+91 78800 38782 ,</p>
                                    </a>
                                </li> */}
                                <li style={{ marginRight: '5px' }}>
                                    <a href='tel: +91 93222 28089'>
                                        <p>+91 93222 28089 ,</p>
                                    </a>
                                </li>
                                <li style={{ marginRight: '20px' }}>
                                    <a href='tel: 9840093858'>
                                        <p>+91 98400 93858</p>
                                    </a>
                                </li>

                                <li >
                                    <a href='mailto: sales@infairs.co.in'>
                                        <p>sales@infairs.co.in</p>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="row pt-1 pe-0 align-items-center" style={{ backgroundColor: "#ffffff" }}>
                        <div className='col-xl-3 col-lg-2 col-md-2 col-5'>
                            <a href='/'>
                                <img src={logo} width="100%" className='img_logo' />
                            </a>
                        </div>

                        <div className='col-auto d-lg-flex d-none align-items-center mx-auto  pe-xl-3'>
                            <ul className='navbar d-flex align-items-center justify-content-between'>

                                <li><a href="/">Home</a></li>

                                <li><Link
                                    onClick={() => handleLinkClick('overview-navbar')
                                    }
                                    to="/#overview"
                                >Overview</Link></li>
                                <li><Link
                                    onClick={() => {
                                        handleLinkClick('about')
                                    }}
                                    to="/#about"
                                >About the Show</Link></li>
                                {/* <li><a href="https://fashionjalsa.in/hideindia_goa/" target='_blank' rel="noopener noreferrer">Events</a> </li> */}
                                <li><a href="/events">Events</a> </li>

                                <li><a href="/about">About Us</a></li>

                                {/* <li><a href="/contact">Contact Us</a></li> */}

                                <li>
                                    <div class="dropdown">
                                        <button class="dropbtn">Gallery</button>
                                        <div class="dropdown-content">
                                            <a href="/gallery" ref={first}>Goa Edition</a>
                                            <a href="/videos" ref={first}>Videos</a>

                                        </div>
                                    </div>
                                </li>

                                <li>
                                    <div class="dropdown">
                                        <button class="dropbtn">Visitors</button>
                                        <div class="dropdown-content">
                                            <a href="/visitor" ref={first}>Who should visit</a>
                                            <a href="/travel" ref={first}>Travel & Stay</a>
                                            <a href="/visitor-registration">Visitor Registration</a>
                                        </div>
                                    </div>
                                </li>

                                <li>
                                    <div class="dropdown">
                                        <button class="dropbtn">Exhibitors</button>
                                        <div class="dropdown-content">
                                            {/* <a href="#">Exhibitors Brochure</a> */}
                                            <a href="/exhibitor_profile">Exhibitor Profile</a>
                                            <a href={pdf} target='_blank' >Post Event Report</a>

                                            <a href="/exhibitor-registration">Book Your Stall</a>
                                            <a href={pdfStall} target="_blank">Stall Layout</a>
                                            <a href={pdf1} target="_blank">Download Brochure</a>


                                        </div>
                                    </div>
                                </li>

                                <li><a href="/seminar-registration">Seminar</a></li>
                                <li>
                                    <a href='/exhibitor-registration' target="_blank" className='stall_button stall_button-top-nav' style={{ color: '#fff', padding: '5px 8px' }}>Book Your Stall</a>
                                </li>
                                <li>
                                    <a href='/visitor-registration' target="_blank" className='stall_button stall_button-top-nav' style={{ color: '#fff', padding: '5px 8px' }}>Visitor Registration</a>
                                </li>
                            </ul>
                        </div>

                        <button class="btn btn-primary d-block me-3 ms-auto d-lg-none col-auto p-0 m-0" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample"
                            aria-controls="offcanvasExample"
                            style={{ float: "right", backgroundColor: "#fff", borderColor: "#fff" }} >
                            <img src='/assets/images/fabars.svg' className="img-fluid img-responsive" style={{ width: "40px", }} />
                        </button>


                        <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample"
                            aria-labelledby="offcanvasExampleLabel">
                            <div class="offcanvas-header">
                                <a href="/" className='phn-logo-hide-india'><img src={logo} alt="" class="img-fluid img_logo" /></a>
                                <button class="btn-close text-reset" data-bs-dismiss="offcanvas" id='offcanvas-btn-close' ></button>
                            </div>
                            <div class="offcanvas-body">
                                <ul class="navigation clearfix">
                                    <li class="current"><a href="/">Home</a></li>

                                    <li><Link
                                        to="/#overview"
                                        onClick={() => {
                                            handleLinkClick('overview-navbar')
                                            document.querySelector('.offcanvas-start').classList.remove("show")
                                            document.querySelector('.offcanvas-start').style.visibility = "hidden";
                                            document.querySelector('.modal-backdrop').classList.remove("show")
                                        }}
                                    >Overview</Link> </li>
                                    <li><div
                                        to="/#about"
                                        onClick={() => {
                                            handleLinkClick('about')
                                            document.querySelector('.offcanvas-start').classList.remove("show")
                                            document.querySelector('.offcanvas-start').style.visibility = "hidden";
                                            document.querySelector('.modal-backdrop').classList.remove("show")
                                        }}
                                    >About the show</div> </li>
                                    {/* <li><a href="https://fashionjalsa.in/hideindia_goa/" target='_blank' rel="noopener noreferrer">Events</a> </li> */}
                                    <li><a href="/events">Events</a> </li>

                                    <li><a href="/about">About Us</a> </li>
                                    <li><a href="/furniture-zone">Furniture Zone</a> </li>
                                    <li><a href="/furnishing-zone">Furnishings Zone</a> </li>
                                    <li><a href="/alfresco-zone">AL Fresco Zone</a> </li>
                                    <li><a href="/Lighting-zone">Lighting Zone</a> </li>
                                    <li><a href="/Surfacing-zone">Surfacing Zone</a> </li>

                                    <li><div class="dropdown">
                                        <button class="dropbtn">Visitors</button>
                                        <div class="dropdown-content">
                                            <a href="/visitor">Who should visit</a>
                                            <a href="/travel">Travel & Stay</a>
                                            <a href="/visitor-registration">Visitor Registration</a>
                                        </div>
                                    </div>
                                    </li>
                                    {/* <li><a href="/contact">Contact us</a></li> */}
                                    <li><div class="dropdown">
                                        <button class="dropbtn">Gallery</button>
                                        <div class="dropdown-content">
                                            <a href="/gallery" ref={first}>Goa Edition</a>

                                        </div>
                                    </div>
                                    </li>

                                    <li><div class="dropdown">
                                        <button class="dropbtn">Exhibitors</button>
                                        <div class="dropdown-content">
                                            <a href="/exhibitor_profile">Exhibitor Profile</a>
                                            <a href="/exhibitor-registration">Book Your Stall</a>
                                            <a href={pdfStall} target="_blank">Stall Layout</a>
                                            <a href={pdf1} target="_blank">Download Brochure</a>

                                        </div>
                                    </div>
                                    </li>
                                    <li><a href="/seminar-registration">Seminar</a></li>
                                    <li className='my-2'>
                                        <a href='/exhibitor-registration' target="_blank" className='stall_button stall_button-top-nav' style={{ border: '1px solid #000', background: 'white', padding: '5px 33px' }}>Book Your Stall</a>
                                    </li>
                                    <li>
                                        <a href='/visitor-registration' target="_blank" className='stall_button stall_button-top-nav' style={{ border: '1px solid #000', background: 'white', padding: '5px 20px' }}>Visitor Registration</a>
                                    </li>


                                    <hr />

                                    <li>
                                        {/* <i class="fa fab-envelope" aria-hidden="true" style={{ color: "#F4BE5A" }} ></i>&nbsp;  */}

                                        <h6 className='fs-6'>
                                            <FiMail className='fs-5 me-3' style={{ color: "#F4BE5A" }} />
                                            <a
                                                href="mailto:sales@infairs.co.in" style={{ fontWeight: 500 }}>sales@infairs.co.in</a>
                                        </h6>
                                    </li>
                                    <li>
                                        <div className='d-flex'>
                                            <div>
                                                <FaPhoneAlt className='ms-1 me-3' style={{ color: "#F4BE5A" }} />
                                            </div>
                                            <div className='my-auto pt-2'>
                                                <h6 className='mt-2 mb-1' style={{ fontWeight: 500 }}>
                                                    Jivesh Sachdev<br />
                                                </h6>
                                                <h6 className='fs-6 my-2' style={{ fontWeight: 500 }}>
                                                    <a href="tel:9322228089" style={{ color: "#000000" }}>
                                                        +91 93222 28089
                                                    </a>
                                                </h6>
                                                <h6 className='mt-2 mb-1' style={{ fontWeight: 500 }}>
                                                    Renit Daniel<br />
                                                </h6>
                                                <h6 className='fs-6 my-2' style={{ fontWeight: 500 }}>
                                                    <a href="tel:9840093858" style={{ color: "#000", fontWeight: 500 }}>
                                                        +91 98400 93858
                                                    </a>
                                                </h6>
                                            </div>
                                        </div>
                                    </li>
                                </ul>

                                <hr />
                                <div className='row'>
                                    <div className='col-auto d-flex align-items-center'>
                                        <ul className='d-flex'>
                                            <li>
                                                <a href='https://www.facebook.com/hideindiaexpo/' target="_blank">
                                                    <img src='/assets/images/fbnw.svg' style={{ marginRight: '15px', paddingBottom: '5px', paddingTop: '5px', border: '1px solid #f4be5a', borderRadius: '5px', padding: '5px' }} />
                                                </a>
                                            </li>

                                            <li>
                                                <a href='https://www.instagram.com/hideindiaexpo/' target='_blank'>
                                                    <img src='/assets/images/instanw.svg' style={{ marginRight: '15px', paddingBottom: '5px', paddingTop: '5px', border: '1px solid #f4be5a', borderRadius: '5px', padding: '5px' }} />
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className='row'>

                        <div className='col-12 bg-white'>
                            <marquee>
                                <a href='/exhibitor-registration' onMouseOver={() => document.querySelector("marquee").stop()}
                                    onMouseLeave={() => document.querySelector("marquee").start()} target="_blank" className='stall_button' style={{ color: '#fff', marginRight: '50px' }}>Book Your Stall</a>

                                <a href='/visitor-registration' onMouseOver={() => document.querySelector("marquee").stop()} onMouseLeave={() => document.querySelector("marquee").start()} target="_blank" className='stall_button' style={{ color: '#fff', marginRight: '50px' }}>Visitor Registration</a> */}

                    {/* <a href='/seminar-registration' onMouseOver={() => document.querySelector("marquee").stop()} onMouseLeave={() => document.querySelector("marquee").start()} target="_blank" className='stall_button' style={{ color: '#fff' }}>Seminar Registration</a> */}


                    {/* 

                            </marquee>
                        </div>

                    </div> */}

                </div>

            </header>




            {/* <div class="mobilemenubutton position-fixed pt-2" style={{ backgroundColor: '#ffffff', width: '100%' }}>
                <a href="/">
                    <img src={logo} alt="" class="imglogomobile img-responsive img-fluid" style={{ width: '60%' }} />
                </a>

                <button class="btn btn-primary nav-btn" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample"
                    aria-controls="offcanvasExample"
                    style={{ float: "right", marginTop: "0px", backgroundColor: "#fff", borderColor: "#Fff" }} >
                    <img src='../assets/images/fabars.svg' className="img-fluid img-responsive" style={{ width: "40px", }} />
                </button>

            </div>

             */}
        </>

    )
}

export default Header